import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import FooterContato from '../../components/FooterContato';
import FooterMenu from '../../components/FooterMenu';
import './styles.css';

export default function EstruturaOrganizacional() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Time de profissionais especialistas em agronegócio</title>
                <meta name="description" content="Conheça nosso time de profissionais especialista em agronegócio, que trabalham em conjunto para garantir sempre a melhor safra" />
            </Helmet>

            <div className="container" style={{ background: '#ebebeb' }}>
                <div className="content-container">
                    <div className="pt-100 text-left">
                        <h1 style={{ marginBottom: '30px' }}>{t('estrutura-organizacional')}</h1>
                        <p>{t('estrutura-organizacional-descricao-1')}</p>
                        <br />
                        <p>{t('estrutura-organizacional-descricao-2')}</p>
                        <br />
                    </div>

                    <div className="time-gestao-item text-center">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-adalton-Reis.png" alt="Adalton reis" style={{ height: '165px' }} />

                        <div className="card card-dados-equipe text-left" >
                            <h2 className="text-red">Adalton Reis</h2>
                            <p>Diretor Presidente</p>
                        </div>
                    </div>

                    <div className="time-gestao-item text-center">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Cicero-Cipriano.png" alt="Cícero Cipriano" style={{ height: '165px' }} />

                        <div className="card card-dados-equipe text-left" >
                            <h2 className="text-red">Cicero Cipriano</h2>
                            <p>Diretor Superintendente</p>
                        </div>
                    </div>

                    <div className="time-gestao-item text-center">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Aparecido-Adriano.png" alt="Aparecido Adriano" style={{ height: '165px' }} />

                        <div className="card card-dados-equipe text-left" >
                            <h2 className="text-red">Aparecido Adriano</h2>
                            <p>Administrador de Produção</p>
                        </div>
                    </div>

                    <div className="time-gestao-item text-center">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Thiago-Rodrigues.png" alt="Thiago Rodrigues" style={{ height: '165px' }} />

                        <div className="card card-dados-equipe text-left" >
                            <h2 className="text-red">Thiago Rodrigues</h2>
                            <p>Contador Responsável</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContato />
            <FooterMenu />
        </>
    );
}
