import React from 'react';

export default function SojaTableLavouras() {
  return (
    <div>
        <table className="table-default">
            <thead>
                <tr>
                    <th colSpan="4">LAVOURAS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1º</td>
                    <td>Soja</td>
                    <td>129.239.714.325</td>
                    <td>21,5%</td>
                </tr>
                <tr>
                    <td>2º</td>
                    <td>Milho</td>
                    <td>59.771.439.189</td>
                    <td>9,9%</td>
                </tr>
                <tr>
                    <td>3º</td>
                    <td>Cana-de-açúcar</td>
                    <td>57.672.044.386</td>
                    <td>9,6%</td>
                </tr>
                <tr>
                    <td>4º</td>
                    <td>Algodão herbáceo</td>
                    <td>40.411.640.707</td>
                    <td>6,7%</td>
                </tr>
                <tr>
                    <td>4º</td>
                    <td>Café</td>
                    <td>19.495.238.336</td>
                    <td>3,5%</td>
                </tr>
            </tbody>
        </table>
    </div>
  );
}
