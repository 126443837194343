import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import './styles.css';

function MapContainer({ google, mapStyles }) {
    const location = { lat: -19.9205665, lng: -43.937536 };

    return (
        <div className="map-container">
            <Map
                style={mapStyles || { width: '400px', height: '250px' }}
                google={google}
                zoom={14}
                initialCenter={location}
                streetViewControl={false}
                mapTypeControl={false}
                fullscreenControl={false}
            >
                <Marker
                    title={"Localização"}
                    name={"SOMA"}
                    position={location}
                />
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA5QjvUhd1W_k9fpiOe18yKwQp1TjG8Gns'
})(MapContainer);