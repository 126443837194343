import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.css';

export default function FooterMenu() {
    const { t } = useTranslation();
    const match = useRouteMatch();

    function getOptionLink(option) {
        window.scrollTo(0, 0)
        let pathname = match.url.split('/');
        return `/${pathname[1]}/${option}`;
    }

    return (
        <div className="bg-dark">
            <div className="section-container footer-menu-container">
                <div className="footer-menu-1">
                    <p>MIDAS AGRONEGÓCIO</p>
                    <p>Afonso Pena, 867, sl 1508</p>
                    <p>Centro, Belo Horizonte - Minas Gerais</p>
                    <p>Brasil</p>
                    <br />
                    <p>© Midas Agronegócio</p>
                </div>

                <div className="footer-menu-2">
                    <ul>
                        <li>
                            <Link to={getOptionLink('quem-somos')}>{t('quem-somos')}</Link>
                        </li>
                        <li>
                            <Link to={getOptionLink('soja')}>{t('soja')}</Link>
                        </li>
                        <li>
                            <Link to={getOptionLink('contato')}>{t('contato')}</Link>
                        </li>
                        <li>
                            <Link to={getOptionLink('noticias')}>{t('noticias')}</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer-menu-3">
                    <ul>
                        <li>
                            <Link to={getOptionLink('mapa-site')}>{t('mapa-site')}</Link>
                        </li>
                        <li>
                            <Link to={getOptionLink('politica-privacidade')}>{t('politica-privacidade')}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
