const INITIAL_STATE = {
  data: [
    {
      route: 'novo-horizonte',
      tag: 'FUTURO',
      titulo: 'Um novo horizonte para o agronegócio',
      descricao: 'Em 2018 a receita com a exportação de soja brasileira foi de R$ 137 bilhões, o valor mais elevado da história, superando em 29,0% o recorde anterior, apurado no ano de 2017, quando somou o equivalente a R$ 125,6 bilhões.',
      conteudo: 'Teste conteudo pagina'
    },
    {
      route: 'alta-perspectiva',
      tag: 'DADOS',
      titulo: 'Produto com uma alta perspectiva de crescimento para 2020',
      descricao: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil harum culpa voluptate eligendi, rem a officiis labore inventore!',
      conteudo: 'Teste conteudo pagina qualquer'
    },
    {
      route: 'estrutura-robusta',
      tag: 'TESTE',
      titulo: 'Temos robusta estrutura e conhecimento para o desenvolvimento do negócio',
      descricao: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil harum culpa voluptate eligendi, rem a officiis labore inventore!',
      conteudo: 'Teste conteudo outra pagina qualquer'
    }
  ],
  noticiasFiltradas: []
};

export default function noticias(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_NOTICIAS":
      return { ...state, data: [...action.noticias] };
    case "FILTER_NOTICIAS":
      return {
        ...state,
        noticiasFiltradas: action.data
      };
    case "CLEAR_NOTICIAS_FILTER":
      return { ...state, noticiasFiltradas: [] };
    default:
      return state;
  }
}
