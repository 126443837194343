import React from 'react';

export default function SojaTablePecuaria() {
    return (
        <div>
            <table className="table-default">
                <thead>
                    <tr>
                        <th colSpan="4">PECUÁRIA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1º</td>
                        <td>Bovinos</td>
                        <td>82.056.340.018</td>
                        <td>13,6%</td>
                    </tr>
                    <tr>
                        <td>2º</td>
                        <td>Frango</td>
                        <td>62.460.884.088</td>
                        <td>10,4%</td>
                    </tr>
                    <tr>
                        <td>3º</td>
                        <td>Leite</td>
                        <td>32.841.499.775</td>
                        <td>5,5%</td>
                    </tr>
                    <tr>
                        <td>4º</td>
                        <td>Suínos</td>
                        <td>15.911.847.189</td>
                        <td>2,6%</td>
                    </tr>
                    <tr>
                        <td>4º</td>
                        <td>Ovos</td>
                        <td>13.881.829.407</td>
                        <td>2,3%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
