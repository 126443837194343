
/*=================LISTA TEMPORÁRIA DE NOTÍCIAS (substituir por busca em api)========================*/
const listaNoticias = [
    {
        route: 'novo-horizonte',
        titulo: 'Um novo horizonte para o agronegócio',
        descricao: 'Em 2018 a receita com a exportação de soja brasileira foi de R$ 137 bilhões, o valor mais elevado da história, superando em 29,0% o recorde anterior, apurado no ano de 2017, quando somou o equivalente a R$ 125,6 bilhões.',
    },
    {
        route: 'alta-perspectiva',
        titulo: 'Produto com uma alta perspectiva de crescimento para 2020',
        descricao: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil harum culpa voluptate eligendi, rem a officiis labore inventore!',
    },
    {
        route: 'estrutura-robusta',
        titulo: 'Temos robusta estrutura e conhecimento para o desenvolvimento do negócio',
        descricao: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil harum culpa voluptate eligendi, rem a officiis labore inventore!',
    }
]


export const filterNoticias = filter => {
    return dispatch => {
        dispatch(filterNoticiasStarted());
        
        setTimeout(() => { 
            const noticiasFiltradas = listaNoticias
            .map(noticia => noticia.titulo.toLowerCase())
            .filter(titulo => titulo.search(filter) !== -1);
            
            let returnItems = [];
            listaNoticias.map(noticia => noticiasFiltradas.includes(noticia.titulo.toLowerCase()) ? returnItems.push(noticia) : null)
            dispatch(filterNoticiasSuccess(returnItems));
        }, 2000);

        // try {
        //     api.get(`/noticias/filter/${filter}`).then(res => {
        //         dispatch(filterNoticiasSuccess(res.data.noticias));
        //     });
        // } catch (err) {
        //     dispatch(filterNoticiasFailure());
        // }
    };
};

export const setNoticias = noticias => ({
    type: "SET_NOTICIAS"
});

const filterNoticiasSuccess = noticias => ({
    type: "FILTER_NOTICIAS",
    data: [...noticias]
    // data: noticias
});

const filterNoticiasStarted = () => ({
    type: "FILTER_NOTICIAS_STARTED",
    isLoading: true
});

const filterNoticiasFailure = err => ({
    type: "FILTER_NOTICIAS_FAILURE"
});

export const clearNoticiasFilter = () => ({
    type: "CLEAR_NOTICIAS_FILTER"
});
