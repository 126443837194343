export default {
    'pagina-inicial': 'PÁGINA INICIAL',
    'quem-somos': 'QUEM SOMOS',
    'soja': 'SOJA',
    'contato': 'CONTATO',
    'noticias': 'NOTÍCIAS',
    'area-restrita': 'ÁREA RESTRITA',
    'acesso-restrito': 'Acesso restrito',
    'mapa-site': 'Mapa do site',
    'politica-privacidade': 'Política de privacidade',
    'direitos-reservados': 'Todos os direitos reservados',
    'o-que-esta-buscando': 'O que você está buscando?',
    'saiba-mais': 'Saiba mais',
    'comprar-soja': 'Comprar Soja',
    'organico': 'Orgânico',
    'organica': 'Orgânica',
    'transgenico': 'Transgênico',
    'vamos-conversar': 'VAMOS CONVERSAR?',
    'deixe-mensagem': 'DEIXE UMA MENSAGEM',
    'enviar': 'ENVIAR',
    'outros-meios-contato': 'Outros meios de contato',
    'relacao-investidores': 'Relação com investidores',
    'soja-estoque': 'SOJA ESTOQUE',
    'soja-futura': 'SOJA FUTURA',
    'pedir-orcamento': 'PEDIR ORÇAMENTO',
    'voce-encontrou-seu-fornecedor': 'Você encontrou o seu fornecedor de soja',
    'estrutura-corporativa': 'Estrutura Corporativa',
    'portal-investidor': 'Portal do investidor',
    'contato-description': 'Tire suas dúvidas, faça elogios, sugestões, críticas ou peça um orçamento. Estamos aqui para atendê-lo',

    'carroussel-1': 'EFICIÊNCIA E INOVAÇÃO NO PLANTIO DE SOJA FOCADO NA QUALIDADE.',
    'carroussel-2': 'UMA COMPANHIA COM SÓLIDO CONHECIMENTO DO MERCADO.',
    'carroussel-3': 'ROBUSTA ESTRUTURA PARA O DESENVOLVIMENTO.',

    'pagina-inicial.estrutura-corporativa': 'ESTRUTURA CORPORATIVA',
    'pagina-inicial.time-gestao': 'Time de gestão',
    'pagina-inicial.section-1.title': 'Um novo horizonte para o agronegócio',
    'pagina-inicial.section-1.content': 'A Midas Agronegócio tem o compromisso de maximizar a produção de forma que a colheita seja destinada a construir negócios de sucesso e comunidades enriquecidas.',
    'pagina-inicial.section-1.end': 'Vamos Juntos?',
    'pagina-inicial.section-2.title': 'Produção focada na qualidade',
    'pagina-inicial.section-2.subtitle': 'O QUE FAZEMOS',
    'pagina-inicial.section-2.content': 'Estamos olhando para o plantio de soja com a cabeça do século 21. Reunimos pessoas, tecnologia e conhecimento para potencialização da produção e comercialização, garantindo maior rentabilidade dos investimentos e alcançando o objetivo de entregar a melhor soja.',
    'pagina-inicial.section-2.end': 'Quer saber mais?',
    'pagina-inicial.section-3.tag': 'NOSSO PRODUTO: SOJA',
    'pagina-inicial.section-3.title': 'Um setor que movimenta a economia mundial',
    'pagina-inicial.section-3.content-1': 'Com a segurança de um mercado comprovadamente sólido, em acelerada expansão e a tranquilidade de que este mercado é grande o suficiente para acomodar muitos negócios.',
    'pagina-inicial.section-3.content-2': 'A soja se destaca como um dos principais produtos desse setor, o Brasil, que está entre os maiores produtores do mundo possui potencial de expansão em área cultivada, podendo mais do que duplicar a produção.',
    'pagina-inicial.section-3.end': 'Quer saber mais?',
    'pagina-inicial.time-gestao-descricao-completa': 'Ver descrição completa',
    'pagina-inicial.footer.title': 'Já pensou em investir no Agronegócio?',
    'pagina-inicial.footer.button': 'Quero informações',

    'quem-somos.onde-estamos': 'ONDE ESTAMOS',
    'quem-somos.section-1.title': 'ATUAMOS NA PRODUÇÃO E COMERCIALIZAÇÃO DA SOJA',
    'quem-somos.section-1.subtitle' : 'MIDAS AGRONEGÓCIO',
    'quem-somos.section-1.content-1': 'A Midas Agronegócio é uma companhia com sólido conhecimento do mercado de agronegócio e temos como foco a transformação do atual modelo brasileiro. Atuamos na produção de soja, na comercialização de grãos e insumos agrícolas, sempre orientado pela qualidade das sementes e pela maximização da produção de forma que a colheita dê muito mais lucro, beneficiando todos os envolvidos.',
    'quem-somos.section-1.content-2': 'Sob a chancela da Flowy Businesses do Brasil S.A, uma holding com capital de 10 milhões, temos robusta estrutura e conhecimento para o desenvolvimento do negócio tanto no tocante a registros e formalidades, quanto ao know how para   potencialização da produção e comercialização da soja, garantindo maior rentabilidade do investimento.',
    'quem-somos.section-2.title': '"Um novo horizonte para o agronegócio brasileiro"',
    'quem-somos.section-2.content-1': 'Estamos olhando para o agronegócio com a cabeça do século 21. Formalização, profissionalismo, tecnologia e pessoas preparadas são os nossos alicerces para potencializar o negócio.  Trazer a modernização para o plantio da soja, enxergando o plantio e a colheita como mecanismos de geração de lucro, saindo da subsistência e tornando-se um negócio rentável, melhorando a vida das pessoas e das comunidades onde estamos presentes.',
    'quem-somos.section-2.content-2' : 'Além de já nascermos em um contexto global, com parcerias no mercado externo definidas, que garantem facilidade de escoar o produto, embasando credibilidade no mercado internacional e maior visibilidade.',
    'quem-somos.section-3.title': 'PROPOSITO MIDAS',
    'quem-somos.section-3.content-1': 'Nosso propósito é trabalhar pela qualidade do produto e garantir a satisfação dos nossos clientes, sem deixar de lado a produção responsável e o aprimorando constante dos nossos processos.',
    'quem-somos.section-3.content-2': 'A União Europeia e a Ásia importam milhões de toneladas da soja produzida no Brasil para alimentar os animais por lá.',
    'quem-somos.dropdown.title-1': 'MISSÃO',
    'quem-somos.dropdown.content-1': 'Contribuir para o desenvolvimento sustentável do agronegócio brasileiro ',
    'quem-somos.dropdown.title-2': 'VISÃO',
    'quem-somos.dropdown.content-2': '',
    'quem-somos.dropdown.title-3': 'VALORES',
    'quem-somos.dropdown.content-3': '',
    'quem-somos.section-5.title': 'O NEGÓCIO',
    'quem-somos.section-5.content': 'Entre plantar, cultivar, colher e vender há um longo caminho para percorrer. Acreditamos na importância do trabalho em conjunto, no poder da tecnologia no campo e na simplicidade dos processos para aumentar os resultados. Cada etapa do nosso negócio é planejada para impactar positivamente toda a cadeia, e promove o fortalecimento das relações com agilidade e transparência nas informações.',

    'soja.section-1.title': 'SOJA: O PRODUTO QUE MOVIMENTA A ECONOMIA MUNDIAL',
    'soja.section-1.content-1': 'A soja é hoje o produto mais importante do agronegócio mundial. Ela é utilizada de diversas formas, seja para alimentação de animais, produção de óleo vegetal, composição em produtos como sabão, detergente e serve na fabricação de biocombustível, um setor atualmente em forte expansão.',
    'soja.section-2.title': 'BRASIL É O SEGUNDO MAIOR PRODUTOR DE SOJA MUNDIAL',
    'soja.section-2.content-1': 'Dentre os grandes produtores, o Brasil é o que possui o maior potencial de expansão em área cultivada, podendo mais do que duplicar a produção se for necessário. Nós da Midas dentro desse contexto estamos preparados para esse momento, temos robusta estrutura e conhecimento para o desenvolvimento do negócio.',
    'soja.section-3.title': '49,57% DE RENTABILIDADE',
    'soja.section-3.content': 'Em 2018 a receita com a exportação de soja brasileira foi de R$ 137 bilhões, o valor mais elevado da história, superando em 29,0% o recorde anterior, apurado no ano de 2017, quando somou o equivalente a R$ 125,6 bilhões.',
    'soja.section-4.title': 'Quer saber mais sobre rentabilidade e investimento no agronegócio?',
    'soja.section-4.button': 'ENVIAR',
    'soja.ranking-text': 'Soja ocupa a posição número 1 no ranking de rentabilidade no Valor Bruto da Produção (VBP), sendo o produto mais lucrativo do agronegócio brasileiro.',

    'estrutura-organizacional': 'Estrutura Organizacional',
    'estrutura-organizacional-descricao-1': 'Pessoas é um dos nossos pilares mais importantes para alcançar o sucesso, por isso unimos profissionais altamente qualificados. Contamos com uma equipe formada por mestres e doutores de Universidades renomadas, engenheiros agrônomos com mais de 15 anos de experiência no ramo e uma equipe de advogados responsáveis pela orientação legal para todo o plano de negócio. ',
    'estrutura-organizacional-descricao-2': 'Conheça nosso time de profissionais especialistas em agronegócio, que trabalham em conjunto para garantir sempre a melhor safra.',

    'acesso-conteudos-exclusivos': 'Para aceder aos conteúdos exclusivos da área restrita será necessário fazer login com seu e-mail e senha.',
    'acesso-conteudos-exclusivos-registro': 'Você terá acesso a um material exclusivo sobre os principais temas relacionados ao agronegócio. Tudo o que você precisa entender sobre o setor está aqui. E-books, guias, artigos, vídeos explicativos totalmente gratuitos.',
    'acesso-conteudos-exclusivos-registro-descricao': 'A Midas tem o compromisso com a transparência em todas as nossas operações, sempre pensando em fortalecer ainda mais a nossa relação através do acesso fácil, rápido e seguro às informações. Por isso, nesse espaço, você, investidor Midas, tem acesso a relatórios e informações para acompanhar todas as negociações realizadas, assim como um demonstrativo detalhado de todas as movimentações.',

    'comprar-soja.title': 'Tenha uma soja de qualidade pelo melhor preço.',
    'comprar-soja.organica.content': 'Produzidos livres de agrotóxicos, adubos químicos, hormônios, antibióticos, entre outros produtos sintéticos.',
    'comprar-soja.transgenico.content': 'Sementes melhoradas geneticamente com o objetivo de garantir uma melhor qualidade e aumentar sua produção e resistência às pragas e herbicidas.',

    'footer-contato.fale-conosco-title': 'FALE CONOSCO',
    'footer-contato.fale-conosco-content': 'Entre em contato com nossos especialistas em agronegócio, não perca tempo nem dinheiro.',
    'footer-contato.fale-conosco-button': 'ENTRAR EM CONTATO',
    'footer-contato.conecte-title': 'CONECTE-SE',
    'footer-contato.conecte-content': 'Fique por dentro das novidades da Midas Agronegócio e saiba tudo sobre nós.',
    'footer-contato.conecte-button': 'Linkedin'
}
