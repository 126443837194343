import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { useRouteMatch } from 'react-router-dom';

export default function Registrar({ history }) {
    const { t } = useTranslation();
    const match = useRouteMatch();

    function handleFormSubmit(e) {
        e.preventDefault();
    }

    function handleClickLogin() {
        let pathname = match.url.split('/')
        history.push(`/${pathname[1]}/login`);
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="background-a"></div>
            <div className="background-b" style={{height: '830px'}}></div>

            <div className="registrar-container">

                <div className="pt-100">
                    {/* <img src={iconCadeado} style={{transform: 'scale(2)'}}/> */}
                    <h1 style={{ margin: 0 }}>{t('acesso-restrito')}</h1>
                    <p style={{ fontsize: '1rem' }}>Registrar</p>
                </div>

                <form onSubmit={handleFormSubmit} className="form-container">
                    <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" alt="Traços" style={{ position: 'absolute', bottom: '0', right: '-15px', transform: 'scale(1.3)' }} />

                    <div className="input-container">
                        <input className="input light-input input-large" type="text" placeholder="Nome" />
                    </div>

                    <div className="input-container">
                        <input className="input light-input input-large" type="email" placeholder="E-mail" />
                    </div>

                    <div className="input-container">
                        <input className="input light-input input-large" type="password" placeholder="Senha" />
                    </div>

                    <div className="input-container">
                        <input className="input light-input input-large" type="password" placeholder="Confirmar senha" />
                    </div>

                    <button type="submit" className="btn btn-fill-light" style={{ margin: '20px 5px ' }}>REGISTRAR</button>
                    <button type="submit" className="btn btn-fill-light" onClick={handleClickLogin} style={{ margin: '20px 5px ' }}>Já tenho registro</button>
                </form>

                <div>
                    <p>{t('acesso-conteudos-exclusivos-registro')}</p>
                    <br />
                </div>
            </div>
        </div>
    );
}
