import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-google-invisible-recaptcha';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MapContainer from '../../components/MapContainer';
import './styles.css';

export default function Contato() {
    const { t } = useTranslation();

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [recaptcha, setRecaptcha] = useState({});

    function handleFormSubmit(e) {
        e.preventDefault();

        const body = {
            'TokenCompany': '26a24b21-b24e-4c19-a9c0-4e84d7b1245c',
            'From': email,
            'Name': nome,
            'Text': message,
            'CorporationName': '',
            'Phone': '',
        }

        recaptcha.execute();
        axios.post('https://midasagronegocio.com/api/Email', body).then(data => {
            setNome('');
            setEmail('');
            setMessage('');

            toast.success('Salvo com sucesso !');
        }).catch(() => {
            toast.error('Erro ao salvar informações de contato, tente novamente mais tarde!');
            recaptcha.reset();
        });
    }

    function goToLinkedin() {
        window.open('https://www.linkedin.com/company/midasagronegocio', '_blank')
    }

    return (
        <>
            <Helmet>
                <title>Entrar em contato – Midas Agronegócio</title>
                <meta name="description" content="Verifique aqui os nossos meios de contato direto e a nossa localização. Tire suas dúvidas, faça elogios, sugestões, críticas ou peça um orçamento. Estamos aqui para isso" />
            </Helmet>

            <div style={{ position: 'relative' }}>
                <ToastContainer />
                <div className="background-1"></div>
                <div className="background-2"></div>

                <div className="contato-container">

                    <div className="pt-100">
                        <h1 className="mg-0">{t('vamos-conversar')}</h1>
                        <p>{t('contato-description')}</p>
                        <br />
                        <p className="details-telefone">+55 31 4042 6042</p>
                        <p>contato@midasagronegocios.com</p>
                    </div>

                    <form onSubmit={handleFormSubmit} className="form-container">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" alt="Traços" style={{ position: 'absolute', bottom: '0', right: '-15px', transform: 'scale(1.3)' }} />
                        <h2>{t('deixe-mensagem')}</h2>

                        <div className="input-container">
                            <input value={nome} onChange={e => setNome(e.target.value)} className="input light-input input-large" type="text" required name="name" placeholder="Seu nome" />
                        </div>

                        <div className="input-container">
                            <input value={email} onChange={e => setEmail(e.target.value)} className="input light-input input-large" type="email" required name="email" placeholder="Insira seu e-mail" />
                        </div>

                        <div className="input-container">
                            <textarea value={message} onChange={e => setMessage(e.target.value)} className="input light-input input-large" required style={{ resize: 'none' }} placeholder="Digite aqui sua mensagem" name="custom_comment" id="mensagem" cols="30" rows="10"></textarea>
                        </div>

                        <Recaptcha
                            ref={ref => setRecaptcha(ref)}
                            sitekey="6LdhQOgUAAAAAO8L6Mt3W22umgs0Y4ApzVqx_U9e"
                            onResolved={() => console.log('Human detected.')}
                        />

                        <input type="submit" className="btn btn-fill-light" style={{ marginTop: '20px' }} value={t('enviar')}></input>
                    </form>

                    <div className="contato-info-container">
                        <div>
                            <img className="icon-localizacao" src="https://cdn.midasagronegocio.com/public/image/website/desktop/icon-localizacao.png" alt="ícone localização" />
                            <div className="contato-info-endereco">
                                <p>Afonso Pena, 867, sl 1508</p>
                                <p>Centro, Belo Horizonte - Minas Gerais Brasil</p>
                            </div>
                            <MapContainer mapStyles={{ width: '95%', margin: 'auto', height: '250px', transform: 'translateY(150px)' }} />
                        </div>

                        <div className="contato-info-outros-meios">
                            <p>{t('outros-meios-contato')}</p>
                            <br />
                            <p>{t('relacao-investidores')}</p>
                            <p>31 4042 6042</p>
                            <p>investir@midasagronegocio.com</p>
                            <br />
                            <p>Cícero Cipriano</p>
                            <p>31 99658 2737</p>
                            <p>cipriano@midasagronegocio.com</p>

                            <br />
                            <button onClick={goToLinkedin} className="btn btn-default">
                                Linkedin
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
