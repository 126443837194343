import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { animated } from 'react-spring/renderprops'

import FooterContato from '../../components/FooterContato';
import FooterMenu from '../../components/FooterMenu';
import MapContainer from '../../components/MapContainer';
import './styles.css';

export default function QuemSomos({ history, style }) {
  const { t } = useTranslation();
  const match = useRouteMatch();

  function redirectPage(newPage) {
    let pathname = match.url.split('/')
    history.push(`/${pathname[1]}/${newPage}`);
  }

  return (
    <>
      <Helmet>
        <title>Plantio de soja com a cabeça do século 21 – Midas Agronegócio</title>
        <meta name="description" content="Nós somos uma produtora e comercializadora de soja no Brasil, com conhecimento sólido do setor de agronegócio voltada para a potencialização da produção" />
      </Helmet>

      <animated.div
    className="mainRoute"
    style={{ ...style, background: `#ef5350` }}>
      <div className="container" style={{ background: '#f2efc4' }}>
        <section className="section-container">
          <div className="section-content">
            <div className="text-right section-left">
              <h1 className="tag-title">{t('quem-somos')}</h1>
              <h2 style={{ fontSize: '2rem', marginBottom: 'auto', marginTop: 0 }}>{t('quem-somos.section-1.title')}</h2>
            </div>
            <div className="text-left">
              <p>{t('quem-somos.section-1.content-1')}</p>
              <br />
              <p>{t('quem-somos.section-1.content-2')}</p>
            </div>
          </div>
        </section>

        <div className="bg-dark">
          <section className="section-container">
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/seta-direita.png" className="quem-somos-image" alt="seta" style={{ position: 'absolute', left: '-220px', opacity: '.8', top: '-45px', transform: 'scale(1.5)', zIndex: 1 }} />
            <LazyLoadImage
              className="quem-somos-image adailton-helicoptero"
              src="https://cdn.midasagronegocio.com/public/image/website/desktop/Adalton-saindo-do-helicoptero.png"
              effect="blur"
              alt="Adailton saindo do helicóptero"
            />
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" className="quem-somos-image" alt="Traços" style={{ position: 'absolute', transform: 'rotate(90deg)', right: '265px', top: '-30px' }} />

            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" className="quem-somos-image" alt="Traços" style={{ position: 'absolute', zIndex: 1, left: '430px', bottom: '100px' }} />
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/soja-Brazil.png" className="quem-somos-image" alt="Soja Brasil" style={{ position: 'absolute', height: '335px', left: '-150px', bottom: '100px' }} />

            <div className="section-content">
              <div />

              <div className="text-left">
                <h2 className="section-title">{t('quem-somos.section-2.title')}</h2>
                <br />
                <br />
                <p>{t('quem-somos.section-2.content-1')}</p>
                <br />
                <p>{t('quem-somos.section-2.content-2')}</p>
              </div>
            </div>
          </section>
        </div>

        <section className="section-container">

          {/* <div className="section-content"> */}
          <div style={{ marginTop: '200px' }}>
            <div className="text-left" style={{ maxWidth: '380px' }}>
              <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Soja-na-mao.png" className="quem-somos-image" alt="Soja na mão" style={{ position: 'absolute', right: '160px' }} />

              <h2 className="section-subtitle">{t('quem-somos.section-3.title')}</h2>
              <p>{t('quem-somos.section-3.content-1')}</p>
              <br />
              <p>{t('quem-somos.section-3.content-2')}</p>
            </div>
          </div>

          <br />
          <div className="propositos-item text-left">
            <h2>{t('quem-somos.dropdown.title-1')}</h2>
            <p style={{ maxWidth: '430px' }}>{t('quem-somos.dropdown.content-1')}</p>
          </div>
        </section>


        <div className="bg-dark">
          <section className="section-container">
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/conteiner.png" className="quem-somos-image" alt="Conteiner" style={{ position: 'absolute', left: '0', bottom: '-50px' }} />
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" className="quem-somos-image" alt="Traços" style={{ position: 'absolute', zIndex: 1, left: '-10px', bottom: '150px' }} />

            <div className="section-content">
              <div></div>
              <div className="text-left">
                <h2 className="section-title">{t('quem-somos.section-5.title')}</h2>
                <br />
                <br />
                <p>{t('quem-somos.section-5.content')}</p>
                <br />
                <button onClick={() => redirectPage('comprar-soja')} className="btn btn-light text-center btn-comprar-soja">
                  {t('comprar-soja')}
                </button>
              </div>
            </div>
          </section>
        </div>

        <section className="section-container">
          <div className="section-content quem-somos-onde-estamos-container">
            <div className="text-left" style={{ justifyContent: 'flex-start', maxWidth: '380px' }}>
              <h2 className="section-subtitle">{t('quem-somos.onde-estamos')}</h2>
              <div style={{ marginTop: '20px' }}>
                <p>MIDAS AGRONEGÓCIO</p>
                <p>Afonso Pena, 867, sl 1508</p>
                <p>Centro, Belo Horizonte - Minas Gerais</p>
                <p>Brasil</p>
              </div>
            </div>
            <MapContainer />
          </div>
        </section>


      </div >
      <FooterContato />
      <FooterMenu />

      </animated.div>
    </>
  );
}
