import React from "react";
import PropTypes from "prop-types";
import Footer from "../../components/Footer";
import MinimalNavbar from "../../components/MinimalNavbar";

function MinimalLayout(props) {
    const { children } = props;

    return (
    <>
        <MinimalNavbar />
            {children}
        <Footer />
    </>
    );
}

MinimalLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default MinimalLayout;