import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './styles.css';

export default function Footer() {
  const { t } = useTranslation();
  const match = useRouteMatch();

  function getOptionLink(option) {
    let pathname = match.url.split('/');
    window.scrollTo(0,0);

    if (option === '')
        return `/${pathname[1]}`

    return `/${pathname[1]}/${option}`;
}

  return (
    <div style={{ background: '#fff', color: '#73221a' }}>
      <footer className="footer-container">

        <span style={{ margin: 'auto 0' }}>
          <img className="footer-telefone-icon" src="https://cdn.midasagronegocio.com/public/image/website/desktop/icone-telefone.png" alt="ícone telefone" />
          <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>+55 (31) 4042-6042</p>
        </span>

        <small className="text-center" style={{ margin: 'auto' }}>© Midas Agronegócio {new Date().getFullYear()} - {t('direitos-reservados')}</small>

        <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={getOptionLink('contato')} style={{ margin: 'auto' }}>
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/icone-enviar-mensagem.png" alt="ícone contato" />
          </Link>
          <img style={{ margin: 'auto' }} src="https://cdn.midasagronegocio.com/public/image/logo/Logo-Midas-Agronegocios.png" alt="logo" />
        </span>
      </footer>
    </div>
  );
}
