import React from "react";
import PropTypes from "prop-types";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function MainLayout(props) {
    const { children } = props;

    return (
    <>
        <Navbar />
            {children}
        <Footer />
    </>
    );
}

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default MainLayout;