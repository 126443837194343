import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import FooterContato from '../../components/FooterContato';
import FooterMenu from '../../components/FooterMenu';
import './styles.css';

export default function PoliticaPrivacidade() {
    const match = useRouteMatch();

    function getOptionLink(option) {
        window.scrollTo(0, 0)
        let pathname = match.url.split('/');
        return `/${pathname[1]}/${option}`;
    }

    return (
        <>
            <Helmet>
                <title>Políticas de privacidade do site Midas Agronegócio</title>
            </Helmet>

            <div style={{ paddingTop: '85px', color: '#FFF', background: '#0f3435' }}>
                <div style={{ maxWidth: '800px', margin: 'auto', display: 'flex' }}>
                    <Link to={getOptionLink('mapa-site')} style={{ margin: '5px' }}>
                        Links do site Mapa do site
                </Link>
                    <p> / </p>
                    <Link to={getOptionLink('politica-privacidade')} style={{ margin: '5px', fontWeight: 'bold' }}>
                        Política de Privacidade
                </Link>
                </div>
            </div>

            <div className="container" style={{ background: '#ebebeb' }}>
                <div className="content-container">
                    <div className="text-content text-left">
                        <div className="pt-50">
                            <h1 style={{ margin: 0 }}>Política de Privacidade</h1>
                        </div>

                        <p>
                            A privacidade e a proteção dos seus dados pessoais são um objetivo permanente da Midas Agronegócio.
                            Sabemos como os seus dados são importantes. Nessa página você vai encontrar os termos de privacidade
                            do nosso site, que aborda como coletamos, usamos, divulgamos, transferimos e armazenamos suas informações pessoais.
                    </p>
                        <p>
                            Ao acessar o site, o usuário expressa a sua livre aceitação quanto aos termos destas Diretivas de Privacidade,
                            autorizando a obtenção dos dados e informações aqui mencionados, bem como sua utilização para os fins abaixo
                            especificados. Caso não esteja de acordo com estas Diretivas, o usuário poderá descontinuar o seu acesso ao
                            site a qualquer momento.
                    </p>
                        <p>
                            Os dados recolhidos serão conservados pelo período necessário para a finalidade para o qual foram recolhidos,
                            salvo disposições legais em vigor e/ou verificando-se algum interesse legítimo por parte da Midas Agronegócio
                            para a sua manutenção.
                    </p>
                        <h2 className="text-red"> Proprietário e Controlador de Dados </h2>
                        <p>Midas Agronegócio, Afonso Pena, 867, sl 1508 - Centro, Belo Horizonte - Minas Gerais - Brasi</p>
                        <p>E-mail de contato do proprietário: contato@midasagronegocio.com</p>
                        <h2 className="text-red">Tipos de informações coletamos</h2>
                        <p>
                            Durante a navegação, poderão ser coletadas as seguintes informações: características do dispositivo de acesso,
                            do navegador, Protocolo de Internet com data, hora e origem, informações sobre cliques, páginas acessadas,
                            a página seguinte acessada após a saída do site, qualquer termo digitado no site (inclusive em nossa ferramenta de busca),
                            geolocalização, dentre outras.
                    </p>
                        <h2 className="text-red">Transferência e armazenamento</h2>
                        <p>
                            As informações serão recebidas, tratadas e armazenadas de forma segura e íntegra, respeitando o prazo legal de guarda,
                            mesmo após o término da relação mantida com o usuário do site. O tempo de guarda das informações respeitará as leis
                            vigentes, bem como as melhores práticas de segurança da informação, visando a atender o mais alto nível de
                            confidencialidade, integridade e disponibilidade.
                    </p>
                        <p>
                            As informações obtidas serão consideradas sigilosas e somente serão acessadas por pessoas autorizadas e
                            capacitadas para lhes conferir o tratamento adequado.
                    </p>
                        <h2 className="text-red">Confiabilidade</h2>
                        <p>
                            A Midas Agronegócio não vende ou fornece dados pessoais que possam identificá-lo a terceiros, mas pode no âmbito da sua
                            atividade coletar e compartilhar informação estatística agrupada do sistema com terceiros. Essas estatísticas compartilhadas
                            não conterão nenhuma informação que permita a identificação de um usuário.
                    </p>
                        <h2 className="text-red">Dados Pessoais</h2>
                        <p>
                            Você é responsável por manter a confidencialidade de qualquer nome de usuário e senha. Você será responsabilizado por
                            todos os usos de seu nome de usuário e senha, autorizados ou não por você. Você concorda em imediatamente notificar
                            a Midas Agronegócio sobre qualquer uso não-autorizado de seu nome de usuário e senha. Você concorda e atesta que
                            as informações de caráter pessoal e voluntariamente fornecidas são expressão da verdade e legalmente concebidas.
                            Igualmente, você concorda e aceita em não se passar por outrem, pessoa física ou jurídica, entidade, pública ou privada,
                            ou ainda organizações não governamentais, inclusive funcionários da Midas Agronegócio.
                    </p>
                        <h2 className="text-red">O que são cookies?</h2>
                        <p>
                            Cookies são arquivos de informações enviadas do navegador e armazenada no computador, celular ou outro dispositivo
                            sempre que uma pessoa visita uma página da web.
                    </p>
                        <h2 className="text-red">São dois tipos de cookies existentes:</h2>
                        <ol>
                            <li>os permanentes (que permanecem no computador até serem apagados);</li>
                            <li>os temporários (durando apenas até fechar o navegador).</li>
                        </ol>
                        <p>
                            Por exemplo, a maioria do sites armazenam informações básicas, como endereços IP e preferências sobre idiomas, cores, etc.
                    </p>
                        <h2 className="text-red">Como a Midas Agronegócio usa os cookies?</h2>
                        <p>
                            Os cookies melhoram a funcionalidade da experiência dos usuários nas navegações de sites, pois permitem que o usuário navegue
                            pelas páginas da internet de forma eficaz e assim recordando suas preferências, fazendo o reconhecimento da origem e do
                            último acesso, auxiliando para uma melhoria na experiência da navegação.
                    </p>
                    </div>
                </div>
            </div>

            <FooterContato />
            <FooterMenu />
        </>
    );
}
