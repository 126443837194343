import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './styles.css';

export default function NotFound() {
    const { t } = useTranslation();
    const match = useRouteMatch();

    function getOptionLink(option) {
        window.scrollTo(0, 0)
        let pathname = match.url.split('/');
        return `/${pathname[1]}/${option}`;
    }

    return (
        <>
            <Helmet>
                <title>Erro 404 - Página não encontrada</title>
            </Helmet>

            <div className="container not-found-container">
                <div className="content-container text-left">
                    <div className="pt-100">
                        <p>ERRO 404</p>
                        <h1 className="mg-0">Desculpe, não achamos essa página</h1>
                        <p>A página que você está tentando acessar não existe.</p>
                    </div>
                    <br />
                    <Link to="/">
                        <button className="btn btn-default">PÁGINA INICIAL</button>
                    </Link>

                    <div>
                        <ul className="not-found__menu">
                            <li>
                                <Link to={getOptionLink('quem-somos')}>{t('quem-somos')}</Link>
                            </li>
                            <li>
                                <Link to={getOptionLink('soja')}>{t('soja')}</Link>
                            </li>
                            <li>
                                <Link to={getOptionLink('contato')}>{t('contato')}</Link>
                            </li>
                            <li>
                                <Link to={getOptionLink('noticias')}>{t('noticias')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
