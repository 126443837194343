export default {
    'pagina-inicial': 'HOME',
    'quem-somos': 'ABOUT',
    'soja': 'SOYBEAN',
    'contato': 'CONTACT',
    'noticias': 'NEWS',
    'area-restrita': 'RESTRICTED AREA',
    'acesso-restrito': 'Restricted Access',
    'mapa-site': 'Site map',
    'politica-privacidade': 'Private policy',
    'direitos-reservados': 'All rights reserved',
    'o-que-esta-buscando': 'What you are searching for?',
    'saiba-mais': 'Read more',
    'comprar-soja': 'Buy soy',
    'organico': 'Organic',
    'organica': 'Organic',
    'transgenico': 'Transgenic',
    'vamos-conversar': 'LETS TALK?',
    'deixe-mensagem': 'LEAVE A MESSAGE',
    'enviar': 'SEND',
    'outros-meios-contato': 'Other means of contact',
    'relacao-investidores': 'Investor relations',
    'soja-estoque': 'SOY STOCK',
    'soja-futura': 'SOY FUTURE',
    'pedir-orcamento': 'ASK FOR BUDGET',
    'voce-encontrou-seu-fornecedor': 'You found your soy supplier',
    'estrutura-corporativa': 'Corporate Structure',
    'portal-investidor': 'Investor Portal',
    'contato-description': 'Ask questions, give praise, suggestions, criticism or ask for a quote. We are here to serve you',

    'carroussel-1': 'EFFICIENCY AND INNOVATION IN SOYBEAN PLANTING FOCUSED ON QUALITY.',
    'carroussel-2': 'A CAMPAIGN WITH SOLID MARKET KNOWLEDGE.',
    'carroussel-3': 'ROBUST STRUCTURE FOR DEVELOPMENT.',

    'pagina-inicial.estrutura-corporativa': 'CORPORATE STRUCTURE',
    'pagina-inicial.time-gestao': 'Management team',
    'pagina-inicial.section-1.title': 'A new horizon to the agribusiness',
    'pagina-inicial.section-1.content': 'Midas Agronegócio is committed to maximizing production so that the harvest is destined to build successful businesses and enriched communities.',
    'pagina-inicial.section-1.end': 'Lets go together?',
    'pagina-inicial.section-2.title': 'Quality focused production',
    'pagina-inicial.section-2.subtitle': 'WHAT WE DO',
    'pagina-inicial.section-2.content': 'We are looking at planting soy with the head of the 21st century. We bring together people, technology and knowledge to enhance production and commercialization, ensuring greater return on investments and achieving the goal of delivering the best soy.',
    'pagina-inicial.section-2.end': 'Want to know more?',
    'pagina-inicial.section-3.tag': 'OUR PRODUCT: SOY',
    'pagina-inicial.section-3.title': 'A sector that drives the world economy',
    'pagina-inicial.section-3.content-1': 'With the security of a proven solid, rapidly expanding market and the peace of mind that this market is large enough to accommodate many businesses.',
    'pagina-inicial.section-3.content-2': 'Soy stands out as one of the main products in this sector, Brazil, which is among the largest producers in the world has the potential for expansion in cultivated area, which can more than double production.',
    'pagina-inicial.section-3.end': 'Want to know more?',
    'pagina-inicial.time-gestao-descricao-completa': 'See complete description',
    'pagina-inicial.footer.title': 'Have you thought about investing in Agribusiness?',
    'pagina-inicial.footer.button': 'I want information',

    'quem-somos.onde-estamos': 'WERE WE ARE',
    'quem-somos.section-1.title': 'WE OPERATE IN THE PRODUCTION AND COMMERCIALIZATION OF SOY',
    'quem-somos.section-1.subtitle' : 'MIDAS AGRONEGÓCIO',
    'quem-somos.section-1.content-1': 'Midas Agronegócio is a company with solid knowledge of the agribusiness market and we focus on transforming the current Brazilian model. We operate in the production of soybeans, in the commercialization of grains and agricultural inputs, always guided by the quality of the seeds and the maximization of production so that the harvest yields much more profit, benefiting everyone involved.',
    'quem-somos.section-1.content-2': 'Under the seal of Flowy Businesses do Brasil SA, a holding company with a capital of 10 million, we have a robust structure and knowledge for the development of the business, both in terms of records and formalities, as well as in the know-how to enhance the production and commercialization of soybeans, guaranteeing greater return on investment.',
    'quem-somos.section-2.title': '"A new horizon for Brazilian agribusiness"',
    'quem-somos.section-2.content-1': 'We are looking at agribusiness with the head of the 21st century. Formalization, professionalism, technology and prepared people are our foundations to potentialize the business. Bring modernization to soy planting, seeing planting and harvesting as profit-generating mechanisms, leaving subsistence and becoming a profitable business, improving the lives of people and communities where we operate',
    'quem-somos.section-2.content-2' : 'In addition to being born in a global context, with defined partnerships in the foreign market, which guarantee ease of disposal of the product, supporting credibility in the international market and greater visibility.',
    'quem-somos.section-3.title': 'MIDAS PURPOSE',
    'quem-somos.section-3.content-1': 'Our purpose is to work for the quality of the product and guarantee the satisfaction of our customers, without neglecting responsible production and constantly improving our processes.',
    'quem-somos.section-3.content-2': 'The European Union and Asia import millions of tons of soy produced in Brazil to feed the animals there.',
    'quem-somos.dropdown.title-1': 'MISSION',
    'quem-somos.dropdown.content-1': '',
    'quem-somos.dropdown.title-2': 'VISION',
    'quem-somos.dropdown.content-2': '',
    'quem-somos.dropdown.title-3': 'VALUES',
    'quem-somos.dropdown.content-3': '',
    'quem-somos.section-5.title': 'THE BUSINESS',
    'quem-somos.section-5.content': 'Between planting, cultivating, harvesting and selling there is a long way to go. We believe in the importance of working together, in the power of technology in the field and in the simplicity of processes to increase results. Each stage of our business is designed to positively impact the entire chain, and promotes the strengthening of relationships with agility and transparency in information.',

    'soja.section-1.title': 'SOYBEAN: THE PRODUCT THAT DRIVES THE WORLD ECONOMY',
    'soja.section-1.content-1': 'Soy is today the most important product in agribusiness worldwide. It is used in several ways, be it for animal feed, production of vegetable oil, composition in products such as soap, detergent and serves in the manufacture of biofuel, a sector currently in strong expansion.',
    'soja.section-2.title': 'BRAZIL IS THE SECOND LARGEST SOYBEAN PRODUCER IN THE WORLD',
    'soja.section-2.content-1': 'Among the large producers, Brazil has the greatest potential for expansion in cultivated area, and can more than double production if necessary. We at Midas in this context are prepared for this moment, we have a robust structure and knowledge for the development of the business.',
    'soja.section-3.title': '49.57% PROFITABILITY',
    'soja.section-3.content': 'In 2018, Brazilian soybean export revenue was R$137 billion, the highest value in history, exceeding the previous record by 29.0% in 2017, when it totaled the equivalent of R$125.6 billion.',
    'soja.section-4.title': 'Do you want to know more about profitability and investment in agribusiness?',
    'soja.section-4.button': 'SUBMIT',
    'soja.ranking-text': 'Soy occupies the number 1 position in the ranking of profitability in the Gross Value of Production (VBP), being the most profitable product of Brazilian agribusiness.',

    'estrutura-organizacional': 'Organizational structure',
    'estrutura-organizacional-descricao-1': 'People are one of our most important pillars for achieving success, so we bring together highly qualified professionals. We have a team formed by masters and doctors from renowned Universities, agronomists with over 15 years of experience in the field and a team of lawyers responsible for legal guidance for the entire business plan.',
    'estrutura-organizacional-descricao-2': 'Meet our team of agribusiness specialists, who work together to always guarantee the best harvest.',

    'acesso-conteudos-exclusivos': 'To access the exclusive content of the restricted area you will need to login with your email and password.',
    'acesso-conteudos-exclusivos-registro': 'You will have access to exclusive material on the main topics related to agribusiness. Everything you need to understand about the industry is here. E-books, guides, articles, explanatory videos totally free.',
    'acesso-conteudos-exclusivos-registro-descricao': 'Midas is committed to transparency in all of our operations, always thinking about further strengthening our relationship through easy, fast and secure access to information. Therefore, in this space, you, Midas investor, have access to reports and information to follow all the negotiations carried out, as well as a detailed statement of all transactions.',

    'comprar-soja.title': 'Have quality soy at the best price.',
    'comprar-soja.organica.content': 'Produced free of pesticides, chemical fertilizers, hormones, antibiotics, among other synthetic products.',
    'comprar-soja.transgenico.content': 'Genetically improved seeds in order to guarantee better quality and increase their production and resistance to pests and herbicides.',

    'footer-contato.fale-conosco-title': 'TALK WITH US',
    'footer-contato.fale-conosco-content': 'Get in touch with our agribusiness experts, dont waste time or money.',
    'footer-contato.fale-conosco-button': 'GET IN TOUCH',
    'footer-contato.conecte-title': 'CONNECT',
    'footer-contato.conecte-content': 'Stay on top of Midas Agronegócio news and learn all about us.',
    'footer-contato.conecte-button': 'Linkedin',
}
