import React, { useState, useEffect, useRef } from 'react';
import SliderContent from '../SliderContent'
import Slide from '../Slide'
import Arrow from '../Arrow'
import MinimalNavbar from '../MinimalNavbar'
import './styles.css';


export default function Slider({ slides, autoPlay }) {
  const getWidth = () => window.innerWidth

  const [contentWidth, setContentWidth] = useState(0);
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45
  })

  const { translate, transition, activeIndex } = state
  const autoPlayRef = useRef();
  const resizeRef = useRef();

  useEffect(() => {
    autoPlayRef.current = nextSlide;
    resizeRef.current = handleResize;
  })

  useEffect(() => {
    const play = () => {
      autoPlayRef.current()
    }

    const resize = () => {
      resizeRef.current()
    }

    const interval = setInterval(play, autoPlay * 1000);
    const onResize = window.addEventListener('resize', resize);
    setContentWidth(getWidth() > 600 ? getWidth() * slides.length : getWidth());

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const handleResize = () => {
    setState({ ...state, translate: getWidth(), transition: 0 })
  }

  const nextSlide = () => {
    if (activeIndex === slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth()
    })
  }

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (slides.length - 1) * getWidth(),
        activeIndex: slides.length - 1
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth()
    })
  }

  return (
    <>
      <MinimalNavbar />

      <div className="slider">
        <div className="carroussel-item-icons">
          {slides.map((el, index) => (
            <div key={index} className={activeIndex === index ? 'carroussel-item-icon carroussel-item-icon-selected' : 'carroussel-item-icon'} />
          ))}
        </div>

        <SliderContent translate={translate} transition={transition} width={contentWidth}>
          {slides.map((slide, i) => (
            <Slide key={slide + i} content={slide} />
          ))}
        </SliderContent>

        <div className="slider-arrows" >
          <Arrow direction="right" handleClick={nextSlide} />
          <br />
          <Arrow direction="left" handleClick={prevSlide} />
        </div>
      </div>
    </>
  )
}

Slider.defaultProps = {
  slides: [],
  autoPlay: null
}
