import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import ChangeLanguageButton from '../ChangeLanguageButton';
import searchIconLight from '../../assets/img/icon-search-button-light.png';
import './styles.css';
import SearchContainer from '../SearchContainer';

export default function MobileNavbar({ onChangeRoute, activeRoute, closeMobileNav, mobileNavActivated }) {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const [searchItem, setSearchItem] = useState(false);

    function toggleSearch() {
        setSearchItem(!searchItem);
    }

    return (
        <>
            <div className="navbar-mobile">
                <div style={{ display: 'flex', width: '100vw' }}>
                    <Link to="/" className="logo-navbar" style={{ margin: 'auto', transform: 'scale(.8)' }}>
                        <img src="https://cdn.midasagronegocio.com/public/image/logo/Logo-Midas-Agronegocios.png" alt="logo" />
                    </Link>
                </div>

                <div className={mobileNavActivated ? 'enable' : 'disable'} style={{ background: '#0f3435', width: '100vw', color: 'white', paddingBottom: '20px', boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.35)' }}>

                    {!searchItem ? (
                        <>
                            <div className="navbar-mobile-menu-item">
                                <ChangeLanguageButton isLight={true} />
                            </div>
                            <div className="navbar-mobile-menu-item" style={{ marginBottom: '20px' }}>
                                <button onClick={() => window.open('https://agroinvestidor.midasagronegocio.com/login')} className="btn btn-light">
                                    <span>
                                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/icone-area-restrita-branco.png" style={{ paddingRight: '5px' }} alt="cadeado" />
                                    </span>
                                    {t('area-restrita')}
                                </button>
                            </div>
                            <div className="navbar-mobile-menu-item">
                                <button onClick={() => onChangeRoute('')} className={activeRoute === '' ? 'btn btn-link-mobile nav-item-selected' : 'btn btn-link-mobile'}>
                                    {t('pagina-inicial')}
                                </button>
                            </div>
                            <div className="navbar-mobile-menu-item">
                                <button onClick={() => onChangeRoute(`quem-somos`)} className={activeRoute === 'quem-somos' ? 'btn btn-link-mobile nav-item-selected' : 'btn btn-link-mobile'}>
                                    {t('quem-somos')}
                                </button>
                            </div>
                            <div className="navbar-mobile-menu-item">
                                <button onClick={() => onChangeRoute(`soja`)} className={activeRoute === 'soja' ? 'btn btn-link-mobile nav-item-selected' : 'btn btn-link-mobile'}>
                                    {t('soja')}
                                </button>
                            </div>
                            <div className="navbar-mobile-menu-item">
                                <button onClick={() => onChangeRoute(`contato`)} className={activeRoute === 'contato' ? 'btn btn-link-mobile nav-item-selected' : 'btn btn-link-mobile'}>
                                    {t('contato')}
                                </button>
                            </div>
                            <div className="navbar-mobile-menu-item">
                                <button onClick={() => onChangeRoute(`noticias`)} className={activeRoute === 'noticias' ? 'btn btn-link-mobile nav-item-selected' : 'btn btn-link-mobile'}>
                                    {t('noticias')}
                                </button>
                            </div>
                        </>
                    ) : <SearchContainer toggleSearch={toggleSearch} closeMobileNav={closeMobileNav} mobile={true} />}

                    <div className="navbar-mobile-menu-item" style={{ marginTop: '20px' }}>
                        <img onClick={toggleSearch} src={searchIconLight} alt="search-icon" />
                    </div>
                </div>
            </div>
        </>
    );
}
