import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import producaoMundial from '../../assets/img/grafico-producao-mundial.png';

import FooterMenu from '../../components/FooterMenu';
import FooterContato from '../../components/FooterContato';
import './styles.css';
import Slider from '../../components/Slider';

const content = [
    {
        text: 'carroussel-1',
        image: 'https://cdn.midasagronegocio.com/public/image/website/desktop/Platacao-soja.png',
        imageMobile: 'https://cdn.midasagronegocio.com/public/image/website/mobile/Platacao-soja.png',
    },
    {
        text: 'carroussel-2',
        image: 'https://cdn.midasagronegocio.com/public/image/website/desktop/Soja-Organica.png',
        imageMobile: 'https://cdn.midasagronegocio.com/public/image/website/mobile/Soja-Organica.png'
    },
    {
        text: 'carroussel-3',
        image: 'https://cdn.midasagronegocio.com/public/image/website/desktop/soja-Brazil.png',
        imageMobile: 'https://s3-eu-west-1.amazonaws.com/s3.cdn.midasagronegocio/public/image/website/mobile/soja-Brasil.png'
    },
]

export default function PaginaInicial({ history }) {
    const match = useRouteMatch();
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Modernização e profissionalismo no campo - Midas Agronegócio</title>
                <meta name="description" content="A Midas Agronegócio trabalha com o foco na eficiência e inovação do plantio de soja com qualidade, maximizando a produção e construindo um agronegócio robusto" />
            </Helmet>

            <div className="container">
                <Slider slides={content} autoPlay={5} />

                <section className="section-container">
                    <div className="section-content">
                        <div className="text-right section-left">
                            <h2 className="tag-title">A MIDAS</h2>
                            <h3 className="section-subtitle">{t('pagina-inicial.section-1.title')}</h3>
                        </div>
                        <div className="text-left">
                            <p>{t('pagina-inicial.section-1.content')}</p>
                            <br />

                            <Link to={`${match.url}/quem-somos`}>
                                <button className="section-button">
                                    {t('pagina-inicial.section-1.end')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>

                <div className="bg-dark">
                    <section className="section-container">
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/seta-direita.png" className="section1-seta" alt="" />
                        <LazyLoadImage
                            className="section1-image"
                            src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto1.png"
                            effect="blur"
                            alt="Imagem 1"
                        />

                        <div className="section-content">
                            <div className="text-right section-left">
                                <h2 className="tag-title">{t('pagina-inicial.section-2.subtitle')}</h2>
                                <h3 className="section-subtitle">{t('pagina-inicial.section-2.title')}</h3>
                            </div>
                            <div className="text-left">
                                <p>{t('pagina-inicial.section-2.content')}</p>
                                <br />

                                <Link to={`${match.url}/quem-somos`}>
                                    <button className="section-button section-button-light">
                                        {t('pagina-inicial.section-2.end')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="section-container">
                    <LazyLoadImage
                        src="https://cdn.midasagronegocio.com/public/image/website/desktop/producao-de-soja-brasil.png"
                        className="pagina-inicial-image producao-soja-brasil" alt="Produção de soja no brasil" effect="blur"
                    />

                    <div className="section-content">
                        <div className="text-right section-left section-3">
                            <h2 className="tag-title">{t('pagina-inicial.section-3.tag')}</h2>
                            <h3 className="section-subtitle">{t('pagina-inicial.section-3.title')}</h3>
                        </div>
                        <div className="text-left">
                            <p>{t('pagina-inicial.section-3.content-1')}</p>
                            <br />
                            <p>{t('pagina-inicial.section-3.content-2')}</p>
                            <br />

                            <LazyLoadImage
                                src={producaoMundial} alt="Produção de soja mundial"
                                className="section3-image" effect="blur"
                            />

                            <Link to={`${match.url}/quem-somos`}>
                                <button className="section-button">
                                    {t('pagina-inicial.section-3.end')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="section-container time-gestao-container">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="text-left" style={{ minWidth: '200px' }}>
                            <h2 className="tag-title">{t('pagina-inicial.estrutura-corporativa')}</h2>
                            <h3 className="section-subtitle">{t('pagina-inicial.time-gestao')}</h3>
                            <div>
                                <Link to={`${match.url}/estrutura-organizacional`}>
                                    <button className="btn btn-default" style={{ margin: '20px 0' }}>
                                        {t('pagina-inicial.time-gestao-descricao-completa')}
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'inherit', justifyContent: 'center' }}>
                            <div className="text-center">
                                <LazyLoadImage
                                    src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-adalton-Reis.png"
                                    alt="Adalton Reis" className="time-gestao-image" effect="blur"
                                />
                                <p>Adalton Reis</p>
                                <p>Diretor Presidente</p>
                            </div>
                            <div className="text-center">
                                <LazyLoadImage
                                    src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Cicero-Cipriano.png"
                                    alt="Cícero Cipriano" className="time-gestao-image" effect="blur"
                                />
                                <p>Cícero Cipriano</p>
                                <p>Diretor Superintendente</p>
                            </div>
                            <div className="text-center">
                                <LazyLoadImage
                                    src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Aparecido-Adriano.png"
                                    alt="Aparecido Adriano" className="time-gestao-image" effect="blur"
                                />
                                <p>Aparecido Adriano </p>
                                <p>Administrador de Produção</p>
                            </div>
                            <div className="text-center">
                                <LazyLoadImage
                                    src="https://cdn.midasagronegocio.com/public/image/website/desktop/Foto-Thiago-Rodrigues.png"
                                    alt="Thiago Rodrigues" className="time-gestao-image" effect="blur"
                                />
                                <p>Thiago Rodrigues</p>
                                <p>Contador Responsável</p>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="bg-dark" style={{ padding: '50px' }}>
                    <section className="section-container">
                        <p className="section-subtitle investir-agronegocio">
                            {t('pagina-inicial.footer.title')}
                        </p>
                        <div>
                            <Link to={`${match.url}/contato`}>
                                <button className="btn btn-light text-center">
                                    {t('pagina-inicial.footer.button')}
                                </button>
                            </Link>
                        </div>
                    </section>
                </div>
            </div>

            <FooterContato />
            <FooterMenu />
        </>
    );
}
