import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.css';

export default function Slide({ content }) {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [slideStyle, setSlideStyle] = useState({});

  useEffect(() => {
    const isMobile = window.innerWidth <= 760;

    setSlideStyle({
      height: '800px',
      width: '100%',
      backgroundImage: `url('${isMobile ? content.imageMobile : content.image}')`,
      backgroundSize: 'cover',
      objectFit: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      zIndex: 100
    })
  }, [content])

  return (
    <div style={slideStyle}>
      <div className="slide-content-container">
        <h1 className="text-left slide-text">
          {t(`${content.text}`)}
        </h1>
        <br />
        <Link to={`${match.url}/quem-somos`}>
          <button className="btn btn-saiba-mais">
            {t('saiba-mais')}
          </button>
        </Link>
      </div>
    </div>
  );
}
