import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

export default function CardEmail() {
    const { t } = useTranslation();

    return (
        <>
            <form action="https://midasagronegocio.us19.list-manage.com/subscribe/post?u=c3bbb093f871b538976092765&amp;id=d625ff9082" className="card card-contato" method="post" target="_blank">
                <div className="card-contato-title">
                    <strong className="text-right">{t('soja.section-4.title')}</strong>
                </div>

                <div className="card-contato-input-container" id="mc_embed_signup_scroll">
                    <input className="card-contato-input input" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Insira seu e-mail" required/>

                    <div id="mce-responses" className="clear" style={{display: 'none'}}>
                        <div className="response" id="mce-error-response"></div>
                        <div className="response" id="mce-success-response"></div>
                    </div>

                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                        <input type="text" name="b_c3bbb093f871b538976092765_d625ff9082" tabIndex="-1" />
                    </div>

                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn btn-fill" style={{margin: '0 10px', width: '100px'}} />
                </div>
            </form>
        </>
    );
}