import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useHistory } from 'react-router-dom';

import FooterContato from '../../components/FooterContato';
import FooterMenu from '../../components/FooterMenu';
import bannerImage from '../../assets/img/soja1.jpg';
import seta from '../../assets/img/comprar-soja-seta.png';
import './styles.css';

export default function ComprarSoja() {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();

    function getOptionLink(option) {
        let pathname = match.url.split('/');

        if (option === '')
            return `/${pathname[1]}`

        return `/${pathname[1]}/${option}`;
    }

    function redirectToPage(option) {
        window.scrollTo(0, 0);
        history.push(getOptionLink(option));
    }

    return (
        <>
            <Helmet>
                <title>Comprar soja orgânico ou transgênico, em estoque ou futura.</title>
                <meta name="description" content="Garanta o melhor produto orgânico ou transgênico, seja na compra da soja em estoque ou soja futura. Peça um orçamento agora." />
            </Helmet>

            <div className="container" style={{ background: '#ebebeb' }}>
                <div style={{ position: 'relative' }}>
                    <img src={bannerImage} alt="Banner soja" className="comprar-soja-banner" />

                    <div className="comprar-soja-banner-content text-left">
                        <div style={{ maxWidth: '500px' }}>
                            <h1 className="comprar-soja-title">
                                {t('comprar-soja.title')}
                            </h1>
                        </div>
                        <br />
                        <button className="comprar-soja-large-btn">{t('comprar-soja')}</button>
                    </div>
                </div>

                <div className="content-container" style={{ minHeight: '450px' }}>
                    <div className="comprar-soja-content-container">
                        <div className="comprar-soja-card-container">
                            <img src={seta} alt="seta direita" className="comprar-soja-seta" />

                            <div className="card card-comprar-soja" >
                                <h2 className="text-red">{t('organico')}</h2>
                                <p>{t('comprar-soja.organica.content')}</p>
                            </div>

                            <img src={seta} alt="seta esquerda" className="comprar-soja-seta inverter" />
                        </div>

                        <div className="comprar-soja-card-container">
                            <img src={seta} alt="seta direita" className="comprar-soja-seta" />

                            <div className="card card-comprar-soja" >
                                <h2 className="text-red">{t('transgenico')}</h2>
                                <p>{t('comprar-soja.transgenico.content')}</p>
                            </div>

                            <img src={seta} alt="seta esquerda" className="comprar-soja-seta inverter" />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#f27c51', width: '100vw' }}>
                    <div style={{ maxWidth: '1100px', margin: 'auto' }}>
                        <h2 className="text-left comprar-soja-cards-title">
                            {t('comprar-soja')}
                        </h2>

                        <div className="soja-cards-preco">
                            <div className="comprar-soja-card-preco">
                                <div className="card" style={{ background: '#0f3435' }}>
                                    <p className="text-light comprar-soja-card-preco__title">
                                        {t('soja-estoque')}
                                    </p>
                                </div>
                                <button onClick={() => redirectToPage('contato')} className="comprar-soja-large-btn-link btn-block">
                                    {t('pedir-orcamento')}
                                </button>
                            </div>

                            <div className="comprar-soja-card-preco">
                                <div className="card" style={{ background: '#0f3435' }}>
                                    <p className="text-light comprar-soja-card-preco__title">
                                        {t('soja-futura')}
                                    </p>
                                </div>
                                <button  onClick={() => redirectToPage('contato')} className="comprar-soja-large-btn-link btn-block">
                                    {t('pedir-orcamento')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="comprar-soja-contato-container">
                    <div style={{ maxWidth: '450px', margin: 'auto', marginBottom: '20px' }}>
                        <h2 className="comprar-soja-contato-title">{t('voce-encontrou-seu-fornecedor')}</h2>
                    </div>
                    <div className="comprar-soja-contato-content">
                        <p>+55 31 4042 6042</p>
                        <p>contato@midasagronegocios.com</p>
                    </div>
                </div>

            </div>

            <FooterContato />
            <FooterMenu />
        </>
    );
}
