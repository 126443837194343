import React, { useEffect } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import FooterMenu from '../../components/FooterMenu';
import CardEmail from '../../components/CardEmail';

import banner from './imgs/banner.jpeg';
import img1 from './imgs/imagem1.jpeg';
import img2 from './imgs/imagem2.jpeg';
import './styles.css';

export default function Noticia() {
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();

    useEffect(() => {
        const noticiaSelecionada = params.noticia;
        console.log(noticiaSelecionada);
    }, []);

    function handleClickNoticia() {
        // TODO handle redirect on noticias
        const noticiaSelecionada = 'novo-horizonte'

        let pathname = match.url.split('/')
        history.push(`/${pathname[1]}/noticia/${noticiaSelecionada}`);
    }

    return (
        <>
            <div className="noticia-content-container">
                <img src={banner} alt="Banner notícia" style={{ marginTop: '80px' }} className="noticia-image" />

                <div className="noticia-content">
                    <div className="post-header">
                        <small className="post-tag">FUTURO</small>
                        <h1 className="post-title text-red">Como funciona o agronegócio brasileiro</h1>
                    </div>

                    <p>O agronegócio engloba todos os segmentos da agropecuária e eles não são poucos. Envolve os produtores que estão no campo, sejam grandes, pequenos ou cooperativas agrícolas, mas não para por aí, as inovações tecnológicas que vão de nanotecnologia à fintechs para o setor, os pesquisadores de melhores sementes e defensivos mais eficientes, a logística que leva os alimentos à mesa das pessoas e muito mais fazem parte do agronegócio. Não é à toa que ele é um dos principais fatores de movimentação da economia.</p>
                    <p>O conceito de agronegócio define todas as etapas que acontecem antes, durante e depois dos alimentos serem produzidos nas fazendas. Por isso, se você acha que o agronegócio não faz parte da vida das grandes cidades está muito enganado. Ele se divide em três: <strong>Produtores rurais</strong> (donos de fazendas de quaisquer tamanho), <strong>fornecedores de insumos rurais</strong> (fabricantes de máquinas, sementes, defensivos, startups de tecnologia etc) e <strong>processamento, distribuição e comercialização</strong> (frigoríficos, portos, supermercados, indústria etc). </p>
                    <img src={img1} alt="imagem 1" style={{width: '100%'}}/>
                    <p>No Brasil, só em 2019, o agronegócio <strong>empregou 18,3 milhões de pessoas</strong>, isso corresponde <u>a 19,6% do mercado de trabalho do país</u>. Nessa conta está toda essa diversidade de profissionais: do campo até as pesquisas de inovação do setor. </p>
                    <img src={img2} alt="imagem 2" style={{width: '100%'}}/>
                    <p>Além das inovações tecnológicas, o agronegócio consegue avançar cada vez mais no Brasil em razão a dois grandes fatores: </p>
                    <p>A Balança Comercial do Agronegócio divulgada pelo Ministério da Agricultura, Pecuária e Abastecimento mostra que em 2019, o setor foi responsável por 43,2% da exportação do país. <span className="text-red">Só de dentro para fora</span> do Brasil foram vendidos US$ 96,79 bilhões e deixou um saldo positivo de US$ 83,02 bilhões na balança. Isso indica que quase metade de tudo que é vendido para o exterior envolve o agronegócio e que ele é essencial para o crescimento econômico de país.</p>
                    <p><u>Os 10 produtos mais exportados</u> no último ano foram: grãos de soja (US$ 26.112 milhões), carne bovina (US$7.567 milhões), celulose (US$7.497 milhões), milho (US$7.344 milhões), carne de frango (US$6.895 milhões), farelo de soja (US$5.826 milhões), açúcar (US$5.246 milhões), café em grãos (US$4.544 milhões), madeiras e suas obras (US$3.416 milhões) e algodão (US$2.639 milhões). Os principais compradores de alimentos brasileiros são a China e o Estados Unidos.</p>
                    <p>Para além das cifras, o investimento em agronegócio é essencial para acabar com a fome do mundo. O Brasil é membro fundador da FAO, organização da ONU para Alimentação e Agricultura que desde 1979 é líder internacional para programas de erradicação da fome e da insegurança alimentar. O país contribui sendo liderança no Programa de Cooperação Sul-Sul da FAO e prestando assistência técnica nas áreas de agricultura familiar, alimentação e nutrição, além de conduzir programas de alimentação escolar e meio ambiente.</p>
                    <p>O Brasil é considerado o celeiro da humanidade porque temos capacidade de produzir para nós e para boa parte do restante do mundo. De acordo com a ONU, o país tem potencial para em menos de cinco anos se tornar o maior exportador de alimentos do planeta. Na visão da organização, o agronegócio brasileiro pode otimizar a sua produção de forma sustentável e sem trazer malefícios a população e ao meio ambiente. Para tal, investimento em pesquisa e tecnologia são fundamentais. É sem dúvidas um setor que tem muito para crescer.</p>
                </div>
            </div>


            <div style={{ maxWidth: '1100px', margin: 'auto', marginBottom: '50px' }}>
                <CardEmail />
                {/* <p style={{ marginLeft: '20px', fontSize: '1.5rem' }}>LEIA TAMBÉM</p>

                <div style={{ display: 'flex' }}>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>

            <FooterMenu />
        </>
    );
}
