import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { useDispatch, useSelector } from 'react-redux';

import FooterContato from '../../components/FooterContato'
import FooterMenu from '../../components/FooterMenu'
import CardEmail from '../../components/CardEmail';
import './styles.css'

export default function NoticiaList({ history }) {
  const match = useRouteMatch();
  // const dispatch = useDispatch();

  // const noticias = useSelector(
  //   ({ noticias }) => noticias.data
  // );

  function handleClickNoticia(noticiaSelecionada) {
    let pathname = match.url.split('/')
    history.push(`/${pathname[1]}/noticia/${noticiaSelecionada}`);
  }

  return (
    <>
      <Helmet>
        <title>Por dentro do agronegócio. Tudo sobre plantio de soja</title>
        <meta name="description" content="Tudo sobre a cultura da soja: técnicas de plantio, resultados de produtividade, projeções do mercado, boas práticas e muito mais sobre o agronegócio." />
      </Helmet>

      <div className="noticia-list-container">

        <div className="post-highlight-container post-list-container" onClick={() => handleClickNoticia('como-funciona')}>
          <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/soja-em-pespectiva.png" className="post-image" alt="Imagem soja post 1" />

          <div className="post-list-text">
            <small className="post-tag">FUTURO</small>
            <h2 className="post-title-highlight text-red">Como funciona o agronegócio brasileiro</h2>
            <p>
              Em 2018 a receita com a exportação de soja brasileira foi de R$ 137 bilhões,
              o valor mais elevado da história, superando em 29,0% o recorde anterior,
              apurado no ano de 2017, quando somou o equivalente a R$ 125,6 bilhões.
            </p>
          </div>
        </div>

        <div className="secondary-post-list">
          <div className="post-list-container" onClick={() => handleClickNoticia('conheca-empresas')}>
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Soja-Organica.png" className="post-image" alt="Imagem soja post 2" />

            <div className="post-list-text">
              <small className="post-tag">DADOS</small>
              <h2 className="post-title-highlight text-red">Conheça empresas de sucesso do agronegócio e seus rendimentos</h2>
              <p className="post-highlight-description">
              Investir é transformar dinheiro em mais dinheiro, mas antes de entrar de cabeça em um novo setor é prudente estudar o mercado e entender o que e quem faz parte dele...
              </p>
            </div>
          </div>
        </div>

        {/* <div className="secondary-post-list">
          <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
            <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

            <div className="post-list-text">
              <small className="post-tag">FUTURO</small>
              <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
              <p className="post-highlight-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                harum culpa voluptate eligendi, rem a officiis labore inventore!
              </p>
            </div>
          </div>

          <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
            <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

            <div className="post-list-text">
              <small className="post-tag">FUTURO</small>
              <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
              <p className="post-highlight-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                harum culpa voluptate eligendi, rem a officiis labore inventore!
              </p>
            </div>
          </div>

          <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
            <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

            <div className="post-list-text">
              <small className="post-tag">FUTURO</small>
              <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
              <p className="post-highlight-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                harum culpa voluptate eligendi, rem a officiis labore inventore!
              </p>
            </div>
          </div>
        </div> */}

        <CardEmail />
      </div>

      <FooterContato />
      <FooterMenu />
    </>
  );
}
