import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import './styles.css';

export default function MinimalNavbar() {
    const match = useRouteMatch();
    const { t } = useTranslation();

    return (
        <div className="minimal-navbar-container">
            <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/Logo-midas-agronegocio-branca.png" alt="Logo midas" className="minimal-navbar-logo" />

            {/**
            <Link to="https://agroinvestidor.midasagronegocio.com/login" className="caroussel-area-restrita"> */}
            <div className="caroussel-area-restrita">
                <button onClick={() => window.open('https://agroinvestidor.midasagronegocio.com/login')} className="btn btn-light">
                    <span>
                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/icone-area-restrita-branco.png" style={{ paddingRight: '5px' }} alt="cadeado" />
                    </span>
                    {t('area-restrita')}
                </button>
            </div>
            {/**</Link>*/}
        </div>
    );
}
