import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

import PaginaInicial from './pages/PaginaInicial';
import QuemSomos from './pages/QuemSomos';
import Soja from './pages/Soja';
import Contato from './pages/Contato';
import NoticiaList from './pages/NoticiaList';
import Noticia from './pages/Noticia';
import Login from './pages/Auth/Login';
import Registrar from './pages/Auth/Registrar';
import EstruturaOrganizacional from './pages/EstruturaOrganizacional';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';

import ComprarSoja from './pages/ComprarSoja';
import NotFound from './pages/404-NotFound';
import MapaSite from './pages/MapaSite';
import RouteWithLayout from './components/RouteWithLayout';
import MainLayout from './Layouts/MainLayout';
import MinimalLayout from './Layouts/MinimalLayout';
import ConhecaEmpresas from './pages/NoticiasTemporarias/ConhecaEmpresas';

function Routes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from='/' to={`${match.url}/`} />
      <RouteWithLayout layout={MainLayout} component={PaginaInicial} exact path='/:locale/' />
      <RouteWithLayout layout={MainLayout} component={QuemSomos} exact path={`${match.url}/quem-somos`} />
      <RouteWithLayout layout={MainLayout} component={Soja} exact path={`${match.url}/soja`} />
      <RouteWithLayout layout={MainLayout} component={Contato} exact path={`${match.url}/contato`} />
      <RouteWithLayout layout={MainLayout} component={NoticiaList} exact path={`${match.url}/noticias`} />
      {/* <RouteWithLayout layout={MainLayout} component={Noticia} exact path={`${match.url}/noticia/:noticia`} /> */}
      <RouteWithLayout layout={MainLayout} component={Noticia} exact path={`${match.url}/noticia/como-funciona`} />
      <RouteWithLayout layout={MainLayout} component={ConhecaEmpresas} exact path={`${match.url}/noticia/conheca-empresas`} />
      <RouteWithLayout layout={MainLayout} component={EstruturaOrganizacional} exact path={`${match.url}/estrutura-organizacional`} />
      <RouteWithLayout layout={MainLayout} component={PoliticaPrivacidade} exact path={`${match.url}/politica-privacidade`} />
      <RouteWithLayout layout={MainLayout} component={MapaSite} exact path={`${match.url}/mapa-site`} />
      <RouteWithLayout layout={MainLayout} component={Login} exact path={`${match.url}/login`} />
      <RouteWithLayout layout={MainLayout} component={ComprarSoja} exact path={`${match.url}/comprar-soja`} />
      <RouteWithLayout layout={MainLayout} component={Registrar} exact path={`${match.url}/registro`} />
      {/* <PrivateRouteWithLayout layout={PortalLayout} component={Tabs} exact path={`${match.url}/portal-investidor`}/> */}
      <RouteWithLayout layout={MinimalLayout} component={NotFound} />
    </Switch>
  );
}

export default Routes = connect(
  state => ({
    location: state.router.location
  }),
  null
)(Routes);
