import React, { useState } from 'react';
import { compile, pathToRegexp } from "path-to-regexp";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const generateLanguage = (location) => {
  const ROUTE = '/:locale/:path*';
  let toPath = compile(ROUTE);
  const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);
  const locale = routeComponents[1] === 'en' ? 'pt' : 'en'

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split("/");
  }

  return toPath({
    locale,
    path: subPaths
  });
};

function ChangeLanguageButton({ location, isLight }) {
  const [isPageEnglish, setIsPageEnglish] = useState(false);
  const { i18n } = useTranslation();

  function changePageLanguage() {
    setIsPageEnglish(!isPageEnglish)

    if (isPageEnglish) {
      i18n.changeLanguage('pt');
    }
    else {
      i18n.changeLanguage('en');
    }
  }


  return (
    <Link to={generateLanguage(location)}>
      <button style={{ margin: '10px' }} className={isLight ? 'btn text-light' : 'btn btn-link'} onClick={changePageLanguage}>{isPageEnglish ? 'Portuguese' : 'English'}</button>
    </Link>
  );
}

export default ChangeLanguageButton = connect(
  state => ({
    location: state.router.location
  }),
  null
)(ChangeLanguageButton);
