import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { useRouteMatch, useHistory } from 'react-router-dom';

export default function FooterContato() {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();

    function handleClickContato() {
        window.scrollTo(0,0);
        let pathname = match.url.split('/');
        history && history.push(`/${pathname[1]}/contato`);
    }

    function goToLinkedin() {
        window.open('https://www.linkedin.com/company/midasagronegocio', '_blank')
    }

    return (
        <div className="bg-orange" style={{ padding: '50px' }}>
            <section className="section-container footer-contato-container">

                <span className="text-left" style={{ width: '50%', padding: '20px' }}>
                    <h4 className="section-subtitle">{t('footer-contato.fale-conosco-title')}</h4>

                    <p>{t('footer-contato.fale-conosco-content')}</p>
                    <div>
                        <button className="btn btn-default text-center" onClick={handleClickContato} style={{ padding: '5px 30px', margin: '20px 0' }}>
                            {t('footer-contato.fale-conosco-button')}
                        </button>
                    </div>
                </span>

                <span className="text-left" className="footer-contato-conecte">
                    <h4 className="section-subtitle">{t('footer-contato.conecte-title')}</h4>
                    <p>{t('footer-contato.conecte-content')}</p>
                    <div>
                        <button onClick={goToLinkedin} className="btn btn-default text-center" style={{ padding: '5px 30px', margin: '20px 0' }}>
                            {t('footer-contato.conecte-button')}
                        </button>
                    </div>
                </span>

            </section>
        </div>
    );
}
