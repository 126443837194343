import React, { useState, useEffect, useReducer } from 'react';
import { Link, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import closeSearch from '../../assets/img/icon-close-search.png';

import ChangeLanguageButton from '../ChangeLanguageButton';
import lockIcon from '../../assets/img/icon-cadeado.png';
import SearchContainer from '../SearchContainer';
import MobileNavbar from '../MobileNavbar';
import './styles.css';
import { clearNoticiasFilter } from '../../store/actions/noticias';
import { useDispatch } from 'react-redux';
import { Spring } from 'react-spring/renderprops';


export default function Navbar() {
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const scroll = useScroll();

    const [searchEnabled, setSearchEnabled] = useState(false);
    const [navbarEnabled, setNavbarEnabled] = useState(false);
    const [mobileNavActivated, setMobileNavActivated] = useState(false);
    const [activeRoute, setActiveRoute] = useState('');

    const [isPaginaInicial, setIsPaginaInicial] = useState(true);
    const [isOnTop, setIsOnTop] = useState(true);

    useEffect(() => {
        const pathname = location.pathname;
        setActiveRoute(pathname.split('/')[2] ? pathname.split('/')[2] : '');
        setIsPaginaInicial(location.pathname.split('/').length === 2);

        if (isOnTop && isPaginaInicial) {
            setNavbarEnabled(false);
        }

        if (!isPaginaInicial) {
            setNavbarEnabled(false);
            setSearchEnabled(false);
        }

        const searchText = location.search && queryString.parse(location.search) && queryString.parse(location.search).search;
        const isSearchEmpty = searchText === '';
        if (!isSearchEmpty) {
            setNavbarEnabled(true);
            setSearchEnabled(true);
        } else {
            setSearchEnabled(false);
        }

    }, [location])

    useEffect(() => {
        setIsOnTop(window.scrollY < 50); // Set true if page is on top

        if (!isOnTop) {
            setNavbarEnabled(true);
        }

        if (isOnTop && isPaginaInicial) {
            setNavbarEnabled(false);
        }
    }, [scroll]);

    /**
     * Adiciona antimação para entrada do search conainer
     */
    const slideInOutSearchContainer = () => {
        if (searchEnabled) {
            return 'show-element';
        }
        else {
            return 'hide-element';
        }
    }

    /**
     * Adiciona animação para entrada do navbar container
     */
    const slideInOutNavbarContainer = () => {
        const searchText = location.search && queryString.parse(location.search) && queryString.parse(location.search).search;

        if (!isPaginaInicial || searchText) {
            return 'navbar show-element';
        }
        if (navbarEnabled) {
            return 'navbar show-element'
        }

        return 'navbar hide-element'
    }

    const toggleSearch = () => {
        setSearchEnabled(!searchEnabled);

        if (searchEnabled) {
            dispatch(clearNoticiasFilter());
            const newRoute = delete history.location['search']; // remove parametro 'search' da url
            history.push(newRoute);
        }
    }
    const handleClickMobileNav = () => setMobileNavActivated(!mobileNavActivated);

    function onChangeRoute(option) {
        setSearchEnabled(false);
        setMobileNavActivated(false);
        history.push(getOptionLink(option));
    }

    function getOptionLink(option) {
        let pathname = match.url.split('/');

        if (option === '')
            return `/${pathname[1]}`

        return `/${pathname[1]}/${option}`;
    }

    function hideFadedBackground() {
        if (mobileNavActivated)
            setMobileNavActivated(false);

        if (searchEnabled)
            setSearchEnabled(false);
    }

    return (
        <>
            {(mobileNavActivated || searchEnabled) ? <div className="faded-background" onClick={hideFadedBackground} /> : null}

            <nav className={slideInOutNavbarContainer()}>
                <div className="navbar-content">

                    <MobileNavbar onChangeRoute={onChangeRoute} mobileNavActivated={mobileNavActivated} closeMobileNav={handleClickMobileNav} activeRoute={activeRoute} />

                    {mobileNavActivated ? (
                        <img src={closeSearch} className="icon-navbar-mobile" onClick={handleClickMobileNav} alt="close-search-icon" />
                    ) : (
                            <div className="icon-navbar-mobile" onClick={handleClickMobileNav}>
                                <div style={{ width: '100%', height: '5px', background: '#0f3435', margin: '6px 0' }}></div>
                                <div style={{ width: '100%', height: '5px', background: '#0f3435', margin: '6px 0' }}></div>
                                <div style={{ width: '100%', height: '5px', background: '#0f3435', margin: '6px 0' }}></div>
                            </div>
                        )}


                    <div className="navbar-left">
                        <Link to="/" className="logo">
                            <img src="https://cdn.midasagronegocio.com/public/image/logo/Logo-Midas-Agronegocios.png" alt="logo" />
                        </Link>
                        <ul className="menu-items">
                            <li>
                                <Link to={() => getOptionLink('')} className={activeRoute === '' ? 'btn nav-item nav-item-selected' : 'btn nav-item'}>
                                    {t('pagina-inicial')}
                                </Link>
                            </li>
                            <li>
                                <Link to={() => getOptionLink('quem-somos')} className={activeRoute === 'quem-somos' ? 'btn nav-item nav-item-selected' : 'btn nav-item'}>
                                    {t('quem-somos')}
                                </Link>
                            </li>
                            <li>
                                <Link to={() => getOptionLink('soja')} className={activeRoute === 'soja' ? 'btn nav-item nav-item-selected' : 'btn nav-item'}>
                                    {t('soja')}
                                </Link>
                            </li>
                            <li>
                                <Link to={() => getOptionLink('contato')} className={activeRoute === 'contato' ? 'btn nav-item nav-item-selected' : 'btn nav-item'}>
                                    {t('contato')}
                                </Link>
                            </li>
                            <li>
                                <Link to={() => getOptionLink('noticias')} className={activeRoute === 'noticias' ? 'btn nav-item nav-item-selected' : 'btn nav-item'}>
                                    {t('noticias')}
                                </Link>
                            </li>
                            <li>
                                <button onClick={toggleSearch} style={{ cursor: 'pointer', background: 'none', border: 0, height: '2rem', margin: '10px', outline: 0 }}>
                                    {searchEnabled ? (
                                        <img src={closeSearch} alt="close-search-icon" />
                                    ) :
                                        <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/icone-buscar.png" alt="search-icon" />
                                    }
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="navbar-right">
                        <ul className="menu-items">
                            <li><ChangeLanguageButton /></li>
                            <li>
                                <button onClick={() => window.open('https://agroinvestidor.midasagronegocio.com/login')} className="btn btn-default" style={{ margin: '10px' }}>
                                    <span>
                                        <img src={lockIcon} style={{ paddingRight: '5px' }} alt="cadeado" />
                                    </span>
                                    {t('area-restrita')}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ delay: 1000, duration: 1000 }}
            >
                {props => (
                    <SearchContainer stylesTest={searchEnabled ? slideInOutSearchContainer() : 'disable'} mobile={false} />
                )}
            </Spring>

            {/* <Transition
            native
            items={this.state.showComponent3}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
            >
            {show =>
                show &&
                (props => (
                <animated.div style={props}>
                    <SearchContainer stylesTest={searchEnabled ? slideInOutSearchContainer() : 'disable'} mobile={false}/>
                </animated.div>
                ))
            }
            </Transition> */}
        </>
    );
}

function scrollReducer(state, action) {
    switch (action.type) {
        case "update":
            return { scroll: action.payload };
        default:
            return state;
    }
}

const isClient = typeof window === "object";

function useScroll() {
    const [state, dispatch] = useReducer(scrollReducer, {
        scroll: 0
    });

    function getScroll() {
        return isClient
            ? window.pageYOffset || document.documentElement.scrollTop
            : undefined;
    }

    function handleScroll() {
        dispatch({ type: "update", payload: getScroll() });
    }

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return state;
}
