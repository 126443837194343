import React, { useEffect } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import FooterMenu from '../../../components/FooterMenu';
import CardEmail from '../../../components/CardEmail';

import banner from './imgs/banner.jpeg';
import './styles.css';

export default function ConhecaEmpresas() {
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();

    useEffect(() => {
        const noticiaSelecionada = params.noticia;
        console.log(noticiaSelecionada);
    }, []);

    function handleClickNoticia() {
        // TODO handle redirect on noticias
        const noticiaSelecionada = 'novo-horizonte'

        let pathname = match.url.split('/')
        history.push(`/${pathname[1]}/noticia/${noticiaSelecionada}`);
    }

    return (
        <>
            <div className="noticia-content-container">
                <img src={banner} alt="Banner notícia" style={{ marginTop: '80px' }} className="noticia-image" />

                <div className="noticia-content">
                    <div className="post-header">
                        <small className="post-tag">FUTURO</small>
                        <h1 className="post-title text-red">Conheça empresas de sucesso do agronegócio e seus rendimentos</h1>
                    </div>

                    {/* <p className="post-description"> */}
                    <p>Investir é transformar dinheiro em mais dinheiro, mas antes de entrar de cabeça em um novo setor é prudente estudar o mercado e entender o que e quem faz parte dele. Dentro do agronegócio há os produtores, os pesquisadores que criam sementes, defensivos e processos mais eficientes, as startups de tecnologia que pensam inovações para o setor e todos os que ligam as etapas desde a colheita até a logística que faz o alimento chegar na mesa dos consumidores. </p>
                    <p>O agronegócio brasileiro é considerado um dos melhores investimentos no mercado financeiro. O cenário é animador porque as projeções indicam que até 2050 ele terá um crescimento de três a quatro vezes maior do que os concorrentes globais. Só em 2017, as 400 melhores empresas do agronegócio brasileiro faturaram juntas 221 bilhões de dólares, com lucro de US$ 5,4 bilhões, de acordo com o ranking da Revista Exame¹.</p>
                    <p>Depois desse breve panorama do agronegócio nacional e do porquê ele é uma boa opção para quem quer investir. Vamos conhecer as melhores empresas que atuam no Brasil?</p>
                    <ul style={{listStyleType: 'disc'}}>
                        <li>Cargill Agrícola</li>
                        <p>Foi a nº1 da lista das 400 melhores empresas do Agronegócio da Revista Exame em 2017. A multinacional americana foi criada em 1865 e é uma das maiores empresas de alimento do mundo, está em 70 países e emprega 160 mil pessoas. Só no Brasil são mais de 11 mil funcionários distribuídos em 147 municípios de 17 estados. </p>
                        <p>No relatório anual de 2018, a organização mostra um crescimento de 33% em relação ao ano anterior e uma receita operacional líquida de R$47 bilhões.</p>
                        <p>Marcas como Liza, Pomarola, Pomodoro, Elefante e outras pertencem a Cargill.</p>
                        <li>Bunge</li>
                        <p>A Bunge surgiu nos EUA em 1818, está em 35 países e gera 35 mil postos de trabalho nas áreas de alimento e bioenergia. No Brasil tem mais de 100 instalações que vão desde fábrica, usinas, moinhos a instalações portuárias.</p>
                        <p>Em 2012, o faturamento líquido da operação brasileira representou 27% do resultado mundial da organização que foi de R$33,7 bilhões.</p>
                        <p>Delícia, Soya, Primor, Etti e outras são marcas Bunge.</p>
                        <li>BRF</li>
                        <p>A BRF também atua globalmente, está em mais de 140 países e possui mais de 90 mil funcionários. No Brasil a marca mantém 1 centro de inovação, 35 unidades produtivas, 20 centros de distribuição e conta com mais de 88 mil colaboradores diretos.</p>
                        <p>Em 2018, a receita líquida da organização foi de R$34,4 milhões de acordo com o relatório anual. </p>
                        <p>Atualmente ela é a maior exportadora de frango do mundo. Sadia, Perdigão, Claybom e muitas outras somam as mais de 30 marcas que pertencem a BRF.</p>
                        <li>JBS</li>
                        <p>A JBS é uma multinacional brasileira fundada em 1953, hoje está presente em 15 países e com 230 mil pessoas em seu quadro de funcionários. </p>
                        <p>No terceiro trimestre de 2019 a receita líquida da organização estava em R$52,2 bilhões. Em 2018 a receita havia sido de R$194,7 bilhões.</p>
                        <p>A Friboi, Massa Leve, Doriana são algumas das muitas marcas que a JBS é proprietária.</p>
                        <li>Amaggi Commodities</li>
                        <p>A empresa foi reconhecida pelo Ministério do Desenvolvimento, Indústria e Comércio Exterior como uma das maiores exportadoras de grãos do país. Além do Brasil, a Amaggi tem operação na Argentina, no Paraguai, na Holanda, na Suíça e na China.</p>
                        <p>Um balanço publicado no Diário Oficial do Estado do Mato Grosso, mostra que a organização fechou 2018 com receita líquida de R$ 17,3 bilhões e lucro de R$ 804,8 milhões.</p>
                        <p>Os principais produtos da organização são milho, algodão e soja. A produção é realizada com aproximadamente 4 mil produtores rurais locais e regionais.</p>
                        <hr />
                        <small>
                            ¹A lista das 400 melhores empresas do Agronegócio da Revista Exame pode ser acessada em: 
                            <a href="https://exame.abril.com.br/revista-exame/400-maiores-do-agronegocio-2/" target="_blank">https://exame.abril.com.br/revista-exame/400-maiores-do-agronegocio-2/</a>
                        </small>

                    </ul>
                </div>
            </div>


            <div style={{ maxWidth: '1100px', margin: 'auto', marginBottom: '50px' }}>
                <CardEmail />
                {/* <p style={{ marginLeft: '20px', fontSize: '1.5rem' }}>LEIA TAMBÉM</p>

                <div style={{ display: 'flex' }}>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>

                    <div className="post-card-33pc  post-list-container" onClick={handleClickNoticia}>
                        <img src="https://via.placeholder.com/400x250" className="post-image" alt="Imagem soja post 2" />

                        <div className="post-list-text">
                            <small className="post-tag">FUTURO</small>
                            <h2 className="post-title-highlight text-red">Um novo horizonte para o agronegócio</h2>
                            <p className="post-highlight-description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti mollitia voluptatem
                                necessitatibus iste nulla blanditiis itaque tempore tenetur magni perferendis nihil
                                harum culpa voluptate eligendi, rem a officiis labore inventore!
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>

            <FooterMenu />
        </>
    );
}
