import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Routes from './Routes';

function App() {
  return (
    <>
      <Switch>
          <Route path="/:locale" component={Routes} />
          <Redirect to="/pt" />
      </Switch>
      
    </>
  );
}

export default App 