import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './styles.css';

export default function MapaSite() {
    const match = useRouteMatch();
    const { t } = useTranslation();

    function getOptionLink(option) {
        let pathname = match.url.split('/');

        window.scrollTo(0, 0)
        if (option === '')
            return `/${pathname[1]}`

        return `/${pathname[1]}/${option}`;
    }

    return (
        <>
            <Helmet>
                <title>Mapa do site Midas Agronegócio</title>
            </Helmet>

            <div className="container" style={{ background: '#ebebeb', height: 'calc(100vh - 100px)' }}>
                <div className="content-container">
                    <div className="mapa-site-title pb-30">
                        <h1 className="text-left" style={{ margin: 0 }}>
                            {t('mapa-site')}
                        </h1>
                    </div>

                    <div className="text-left pb-30">
                        <Link to={getOptionLink('')} style={{ fontWeight: 'bold' }}>
                            {t('pagina-inicial')}
                        </Link>

                        <div className="pt-30 mapa-site-items-container">
                            <div className="mapa-site-item-column">
                                <Link to={getOptionLink('quem-somos')}>
                                    {t('quem-somos')}
                                </Link>
                                <Link to={getOptionLink('estrutura-corporativa')}>
                                    {t('estrutura-corporativa')}
                                </Link>
                            </div>
                            <div className="mapa-site-item-column">
                                <Link to={getOptionLink('soja')}>
                                    {t('soja')}
                                </Link>
                                <Link to={getOptionLink('comprar-soja')}>
                                    {t('comprar-soja')}
                                </Link>
                            </div>
                            <div className="mapa-site-item-column">
                                <Link to={getOptionLink('contato')}>
                                    {t('contato')}
                                </Link>
                            </div>
                            <div className="mapa-site-item-column">
                                <Link to={getOptionLink('noticias')}>
                                    {t('noticias')}
                                </Link>
                                <Link to={getOptionLink('portal-investidor')}>
                                    {t('portal-investidor')}
                                </Link>
                            </div>
                            <div className="mapa-site-item-column">
                                <Link to={getOptionLink('politica-privacidade')}>
                                    {t('politica-privacidade')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
