import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18n from './i18n';

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from 'connected-react-router'
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';

const browserHistory = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer(browserHistory), 
    composeEnhancers(
        applyMiddleware(
            thunk, 
            routerMiddleware(browserHistory)
        )
    )
);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <ConnectedRouter history={browserHistory}>
                <App />
            </ConnectedRouter>
        </Provider>
    </I18nextProvider>, 
    document.getElementById('root')
);


serviceWorker.register();