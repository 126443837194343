import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { useRouteMatch, useHistory } from 'react-router-dom';

export default function Login() {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();

    function handleFormSubmit(e) {
        e.preventDefault();
        //TODO redirecionar para projeto portal investidor
        return;
    }

    function getOptionLink(option) {
        let pathname = match.url.split('/');

        window.scrollTo(0, 0)
        if (option === '')
            return `/${pathname[1]}`

        return `/${pathname[1]}/${option}`;
    }

    function handleClickRegistro() {
        let linguagemSelecionada = match.url.split('/')[1];
        history.push('`/${linguagemSelecionada}/registro`');
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="background-a"></div>
            <div className="background-b"></div>

            <div className="login-container">
                <div className="pt-100">
                    <h1 style={{ margin: 0 }}>{t('acesso-restrito')}</h1>
                    <p style={{ fontsize: '1rem' }}>Login</p>
                </div>

                <form onSubmit={handleFormSubmit} className="form-container">
                    <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/trocos2.png" alt="Traços" style={{ position: 'absolute', bottom: '0', right: '-15px', transform: 'scale(1.3)' }} />
                    <h2>Login</h2>

                    <div className="input-container">
                        <input className="input light-input input-large" type="email" placeholder="Seu e-mail" />
                    </div>

                    <div className="input-container">
                        <input className="input light-input input-large" type="password" placeholder="Insira sua senha" />
                    </div>

                    <button type="submit" className="btn btn-fill-light" style={{ margin: '20px 5px ' }}>ENTRAR</button>
                    <button type="submit" onClick={handleClickRegistro} className="btn btn-fill-light" style={{ margin: '20px 5px ' }}>REGISTRAR</button>
                </form>

                <div>
                    <p>{t('acesso-conteudos-exclusivos')}</p>
                    <br />
                </div>
            </div>
        </div>
    );
}
