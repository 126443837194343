import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';

import FooterContato from '../../components/FooterContato';
import FooterMenu from '../../components/FooterMenu';
import numeroUm from '../../assets/img/um.png';
import { useTranslation } from 'react-i18next';
import CardEmail from '../../components/CardEmail';
import SojaTableLavouras from '../../components/SojaTableLavouras';
import SojaTablePecuaria from '../../components/SojaTablePecuaria';
import './styles.css';

export default function Soja({ history }) {
  const match = useRouteMatch();
  const { t } = useTranslation();

  function redirectPage(newPage) {
    let pathname = match.url.split('/')
    history.push(`/${pathname[1]}/${newPage}`);
  }

  return (
    <>
      <Helmet>
        <title>O principal produto do agronegócio brasileiro</title>
        <meta name="description" content="A soja se destaca como um dos principais produtos do agronegócio, o Brasil possui potencial de expansão em área cultivada, podendo mais do que duplicar a produção" />
      </Helmet>

      <div className="container" style={{ background: '#f2efc4' }}>
        <section className="section-container">
          <div className="section-content">
            <div className="text-right section-left">
              <h1 className="section-title">
                {t('soja.section-1.title')}
              </h1>
            </div>
            <div className="text-left">
              <p>{t('soja.section-1.content-1')}</p>
              <br />
              <button onClick={() => redirectPage('comprar-soja')} className="btn btn-default text-center btn-comprar-soja">
                {t('comprar-soja')}
              </button>
            </div>
          </div>
        </section>

        <div style={{ background: '#f27c51' }}>
          <section className="section-container">
              <LazyLoadImage
                className="section2-image"
                src="https://cdn.midasagronegocio.com/public/image/website/desktop/soybean.png"
                effect="blur"
                alt="Soja"
              />
            <div className="section-content">
              <div className="text-right section-left">
                <h2 className="section-title text-left">
                  {t('soja.section-2.title')}
                </h2>
                <div className="text-left">
                  <p>{t('soja.section-2.content-1')}</p>
                  <br />
                  <button onClick={() => redirectPage('comprar-soja')} className="btn btn-default text-center btn-comprar-soja">
                    {t('comprar-soja')}
                  </button>"
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="section-container">
          <div className="section-content">
            <div className="text-right section-left">''
              <h2 className="section-title text-left">
                {t('soja.section-3.title')}
              </h2>
              <div className="text-left">
                <p>{t('soja.section-3.content')}</p>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-dark">
          <section className="section-container">
            <div className="section-content section-info-tables">
              <img src={numeroUm} alt="" className="numero-um" />
              <div>
                <strong className="text-left section-subtitle ranking-soja-text">
                  {t('soja.ranking-text')}
                </strong>
              </div>
            </div>

            <div className="soja-info-tables">
              <SojaTableLavouras />
              <SojaTablePecuaria />
            </div>
          </section>
        </div>

        <section className="section-container">
          <CardEmail />
        </section>

      </div>
      <FooterContato />
      <FooterMenu />
    </>
  );
}
