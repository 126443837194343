import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from "@emotion/core";
import { FadeLoader } from "react-spinners";
import queryString from 'query-string'

import searchIcon from '../../assets/img/icon-search-button-light.png';
import closeSearch from '../../assets/img/icon-close-search-light.png';
import './styles.css';
import { filterNoticias, clearNoticiasFilter } from '../../store/actions/noticias';
import { useDispatch, useSelector } from 'react-redux';

export default function SearchContainer({ stylesTest, toggleSearch, mobile }) {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [inputText, setInputText] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const noticiasFiltradas = useSelector(
        ({ noticias }) => noticias.noticiasFiltradas
    );

    useEffect(() => {
        const searchText = location.search && queryString.parse(location.search) && queryString.parse(location.search).search;
        setInputText(searchText);
        if(searchText) {
            // handleSearchItems();
        }
        console.log('searchText: ', searchText);
    }, [location])

    function clickItem() {
        const noticiaSelecionada = 'novo-horizonte'

        let pathname = match.url.split('/')
        history.push(`/${pathname[1]}/noticia/${noticiaSelecionada}`);
        // closeMobileNav();
    }

    function searchItems(e) {
        e.preventDefault();
        if(!inputText)
            return;
            
        handleSearchItems();
    }

    function handleSearchItems() {
        const query = { search: inputText };
        const searchString = queryString.stringify(query);
        history.push({ search: searchString});

        setShowResults(false);

        dispatch(clearNoticiasFilter());
        dispatch(filterNoticias(inputText));
        
        setShowSpinner(true);
        setTimeout(function(){ 
            setShowSpinner(false);
            setShowResults(true);
        }, 2000);
    }

    function fecharBusca() {
        dispatch(clearNoticiasFilter());
        setShowResults(false);
        toggleSearch();
    }

    return (
        <div className={`${stylesTest} search-container`}>
            <div className="search-content" style={noticiasFiltradas.length > 1 ? {height: 'calc(100vh - 80px)'}: {}}>
                <form onSubmit={e => searchItems(e)} style={{marginTop: '50px'}}>
                    <input value={inputText} onChange={e => setInputText(e.target.value)} id="search-input" placeholder={t('o-que-esta-buscando')} />
                    <img onClick={searchItems} src={searchIcon} className="search-icon" alt="search-icon" />
                </form>

                {showResults ? (
                    <div className="search-results">
                        <div onClick={clickItem}>
                            <p className="text-left text-light"> 
                                {noticiasFiltradas.length === 0 && 'Nenhum resultado encontrado'}
                                {noticiasFiltradas.length === 1 && `${noticiasFiltradas.length} Resultado`}
                                {noticiasFiltradas.length > 1 && `${noticiasFiltradas.length} Resultados`}
                            </p>
                            
                            {noticiasFiltradas.map((noticia, index) => (
                                <div key={index} className="text-left search-result-item" >
                                    <div style={{ padding: '20px 0' }}>
                                        <p className="text-light search-result-item__title">{noticia.titulo.toUpperCase()}</p>
                                        <p className="text-light search-result-item__description">{noticia.descricao}</p> 
                                    </div>

                                    <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/seta-carrosel.png" style={{transform: 'scaleX(-1)'}} alt="seta-direita" className="seta-item-busca"/>
                                </div>
                            ))}
                        </div>
                    </div>
                ): null}

                {showSpinner ? ( 
                    <FadeLoader
                    css={spinnerStyles}
                    size={150}
                    color={'#f2efc4'}
                    loading={true}
                    />
                ) : null}
            </div>

            {mobile ? (
                <img src={closeSearch} onClick={fecharBusca} alt="close-search" style={{position: 'absolute', bottom: 0, margin: '20px auto'}}/>
            ): null}
        </div>
    );
}

const spinnerStyles = css`
    transform: scale(.6);
    display: block;
    margin: 20px auto;
`;
