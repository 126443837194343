import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import noticias from './noticias';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    noticias: noticias,
});

export default rootReducer;
