import React, {useState, useEffect} from 'react'
import "./styles.css";

export default function Arrow ({ direction, handleClick }) {

    const [arrowStyle, setArrowStyle] = useState({});

    useEffect(()=> {
        const alignArrow = direction === 'right' ? `transform: translateY(-50px)` : `transform: translateY(50px)`;

        setArrowStyle({
            zIndex: '999',
            alignArrow,
            cursor: 'pointer',
            alignItems: 'center',
            transition: 'transform ease-in 0.1s',
        })
      }, [])

  return <div
    onClick={handleClick}
    className="arrow-caroussel"
    style={arrowStyle}
  >
    {direction === 'right' ? <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/seta-carrosel.png" style={{transform: 'scaleX(-1)'}} alt="seta direita"/> : <img src="https://cdn.midasagronegocio.com/public/image/website/desktop/seta-carrosel.png" alt="seta esquerda" />}
  </div>
}
